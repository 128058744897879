<template>
  <div class="kline">
    <div id="tv_chart_container"></div>
  </div>
</template>

<script>

import { getKline	} from '@/api/markets'

import { DataFeed, widget as TvWidget } from "tradingview-api";
import { mapGetters } from "vuex";

let timeR3='';
const supported_resolutions = [
  // {text: "1", resolution: "1"},
  // {text: "5", resolution: "5"},
  // {text: "15", resolution: "15"},
  // {text: "30", resolution: "3."},
  // {text: "60", resolution: "60"},
  // {text: "240", resolution: "240"},
  // {text: "D", resolution: "D"},
  // {text: "W", resolution: "W"},
  // {text: "M", resolution: "M"},
  "1",
  "5",
  "15",
  "30",
  "60",
  "240",
  "1D",
  "1W",
  "1M",
];
/**
 * @key Server 端定义字段
 * @value value 对应 supported_resolutions
 */
// 1min, 5min, 15min, 30min, 60min, 4hour, 1day, 1mon, 1week, 1year
const intervalMap = {
  "1min": "1",
  "5min": "5",
  "15min": "15",
  "30min": "30",
  "1hour": "60",
  "4hour": "240",
  "1day": "1D",
  "7day": "1W",
  "30day": "1M",
};
export default {
  name: "KLineWidget",
  props: {
    // symbolInfo: Object,
    pair: String,
    web: String,// K线联调
  },
  data() {
    return {
      newestTime:'',
      since:'',//xt交易所参数
      // symbol: this.symbolInfo.symbol,
      symbol: this.pair,
      exchange: this.web,
      // interval: "15min",
      interval: "15min",
      widget: null,
      datafeed: new DataFeed({
        getBars: (params) => this.getBars(params),
        fetchResolveSymbol: () => this.resolveSymbol(),
        fetchConfiguration: () => {
          return new Promise((resolve) => {
            resolve({
              supported_resolutions: supported_resolutions,
            });
          });
        },
      }),
    };
  },
  computed:{
    ...mapGetters(['account','device','lang','theme','isMobile']),
  },
  methods: {
    resolveSymbol() {
      return new Promise((resolve) => {
        const symbol = this.symbol;
        // const info = this.symbolInfo;
        resolve({
          name: symbol.toLocaleUpperCase(),
          full_name: symbol.toLocaleUpperCase(),
          description: symbol.toLocaleUpperCase(),
          type: symbol.toLocaleUpperCase() + ' on ' + this.exchange.toLocaleUpperCase(),
          session: "24x7",
          // exchange: "HuoBi",
          exchange: this.exchange,// K线联调
          listed_exchange: this.exchange,
          timezone: "Asia/Shanghai",
          format: "price",
          // pricescale: Math.pow(10, info["price-precision"]),//交易对报价的精度（小数点后位数），限价买入与限价卖出价格使用
          // pricescale: 100,
          pricescale: Math.pow(10, 4),
          minmov: 1,
          // volume_precision: info["value-precision"],//交易对交易金额的精度（小数点后位数），市价买入金额使用
          volume_precision: 8,
          // volume_precision: 80,
          has_intraday: true,
          supported_resolutions: supported_resolutions,
        });
      });
    },
    async getBars(params) {
      const symbol = this.symbol;
      const size = window.innerWidth;
      if (!params.firstDataRequest /**是否第一次请求历史数据 */) {
        // 火币接口暂时不支持分段查询历史数据
        return {
          bars: [],
          meta: {
            noData: true,
          },
        };
      }
      
      if (params.resolution !== intervalMap[this.interval]) {
        // this.unsubscribeKLine();
        // console.log(params,params.resolution)
        for (let key in intervalMap) {
          if (intervalMap[key] === params.resolution) {
            this.interval = key;
          }
        }
      }

      // 交易所判定
      var since;
      if(
        this.exchange=='xt'||
        this.exchange=='lbank'||
        this.exchange=='mxc'||
        this.exchange=='bitmart'||
        this.exchange=='bidacoin'||
        this.exchange=='citex'
      ){
        since='0'
      }else if(this.exchange=='gate'){
        since=size > 1000 ? 1000 : size
      }else if(this.exchange=='ok'){
        since=''
      }else{
        // huobi、bian、biconomy、bitwell、bitrue、bibox、zt、mirrorx、bitget、coinw、hotcoin、bidacoin及其他
        since=size > 2000 ? 2000 : size
      }

      const data=await getKline({
        web: this.exchange,
        currency: symbol,
        account: this.account,
        k_type:this.interval,
        cb: since,
      })
      let res;

      // 交易所判定
      // console.log(data)
      if(this.exchange=='xt'){
        res=data.result
      }else if(
        this.exchange=='huobi'||
        this.exchange=='mxc'||
        this.exchange=='bitwell'||
        this.exchange=='bitrue'||
        this.exchange=='bitget'||
        this.exchange=='hotcoin'||
        this.exchange=='bidacoin'
      ){
        res=data.data
      }else if(this.exchange=='lbank'){
        this.since=data.ts
        res=data.data
      }else if(this.exchange=='bibox'){
        res=data.result
      }else if(this.exchange=='mirrorx'){
        res=data.tick
      }else if(this.exchange=='ok'){
        if(data.code=='0'){
          this.since=data.data[0][0]
          res=data.data
        }
      }else if(this.exchange=='coinw'){
        res=data.data
      }else if(this.exchange=='bitmart'){
        res=data.data.klines
      }
      else{
        // gate、biconomy、zt、citex及其他
        res=data
      }

        if (
          params.resolution === intervalMap[this.interval] &&
          params.firstDataRequest &&
          !!res &&res.length
        ) {
          // 轮询
          this.subscribeKLine();
        }

        //K线联调
        if (!res || !res.length) {
          return {
            bars: [],
            meta: { noData: true },
          };
        }
        const list = [];

        // K线联调
        for (let i = 0; i < res.length; i++) {
          const item = res[i];
          // 交易所判定
          if(this.exchange=='xt'){
            list.push({
              time: Number(item.t),
              open: Number(item.o),
              high: Number(item.h),
              low: Number(item.l),
              close: Number(item.c),
              volume: Number(item.v),
            });
          }else if(this.exchange=='lbank'){
            list.push({
              time: item[0]*1000,
              open: Number(item[1]),
              high: Number(item[2]),
              low: Number(item[3]),
              close: Number(item[4]),
              volume: Number(item[5]),
            });
          }else if(this.exchange=='gate'){
            list.push({
              time: item[0]*1000,
              open: Number(item[5]),
              high: Number(item[3]),
              low: Number(item[4]),
              close: Number(item[2]),
              volume: Number(item[6]),
            });
          }else if(this.exchange=='huobi'||this.exchange=='mirrorx'){
            list.push({
              time: item.id * 1000,
              open: item.open,
              high: item.high,
              low: item.low,
              close: item.close,
              volume: item.vol,
            });
          }else if(
            this.exchange=='mxc'||
            this.exchange=='bidacoin'
          ){
            list.push({
              time: item[0]*1000,
              open: Number(item[1]),
              high: Number(item[3]),
              low: Number(item[4]),
              close: Number(item[2]),
              volume: Number(item[5]),
            });
          }else if(
            this.exchange=='bian'||
            this.exchange=='biconomy'||
            this.exchange=='zt'||
            this.exchange=='hotcoin'||
            this.exchange=='citex'
          ){
            list.push({
              time: Number(item[0]),
              open: Number(item[1]),
              high: Number(item[2]),
              low: Number(item[3]),
              close: Number(item[4]),
              volume: Number(item[5]),
            });
          }else if(this.exchange=='bitrue'){
            list.push({
              time: item.i*1000,
              open: Number(item.o),
              high: Number(item.h),
              low: Number(item.l),
              close: Number(item.c),
              volume: Number(item.v),
            });
          }else if(this.exchange=='bibox'){
            list.push({
              time: Number(item.time),
              open: Number(item.open),
              high: Number(item.high),
              low: Number(item.low),
              close: Number(item.close),
              volume: Number(item.vol),
            });
          }else if(this.exchange=='ok'){
            list.push({
              time: Number(item[0]),
              open: Number(item[1]),
              high: Number(item[2]),
              low: Number(item[3]),
              close: Number(item[4]),
              volume: Number(item[6]),
            });
          }else if(this.exchange=='bitget'){
            list.push({
              time: Number(item.ts),
              open: Number(item.open),
              high: Number(item.high),
              low: Number(item.low),
              close: Number(item.close),
              volume: Number(item.baseVol),
            });
          }else if(this.exchange=='coinw'){
            list.push({
              time: Number(item.date),
              open: Number(item.open),
              high: Number(item.high),
              low: Number(item.low),
              close: Number(item.close),
              volume: Number(item.volume),
            });
          }else if(this.exchange=='bitmart'){
            list.push({
              time: Number(item.timestamp*1000),
              open: Number(item.open),
              high: Number(item.high),
              low: Number(item.low),
              close: Number(item.close),
              volume: Number(item.volume),
            });
          }else{
            list.push({
              // time: Number(item.timestamp),
              time: Number(item.time)*1000,
              open: Number(item.open),
              high: Number(item.high),
              low: Number(item.low),
              close: Number(item.close),
              // volume: Number(item.volumeUsd),
              volume: Number(item.volume),
            });
          }
        }
        // console.log('K线数据',list)
        // 交易所判定
        // 最新时间
        if(
          this.exchange=='xt'||
          this.exchange=='huobi'||
          this.exchange=='bitwell'||
          this.exchange=='ok'||
          this.exchange=='coinw'||
          this.exchange=='citex'
        ){
          this.newestTime=list[0].time
        }else{
          this.newestTime=list[list.length-1].time
        }

        // 交易所判定
        // 第二次的cd参数-since
        if(
          this.exchange=='xt'||
          this.exchange=='citex'
        ){
          this.since=this.newestTime?this.newestTime:0
        }else if(
          this.exchange=='mxc'||
          this.exchange=='bitmart'||
          this.exchange=='bidacoin'
        ){
          this.since=this.newestTime?this.newestTime/1000:0
        }

        list.sort((l, r) => (l.time > r.time ? 1 : -1));
        return {
          bars: list,
          meta: {
            noData: !list.length,
          },
        };
    },
    // 获取最新数据
    subscribeKLine() {
      this.loopGet()
    },
    // 时间切换
    unsubscribeKLine() {
      const symbol = this.symbol;
      // 退订
    },
    // 初始化TV
    initTradingView() {
      const symbol = this.symbol;
      var disabled_features=[ 
        //禁用功能（隐藏图标按钮）数组
        "use_localstorage_for_settings",// 禁用本地配置
        'header_symbol_search',//隐藏搜索框
        "timeframes_toolbar", //隐藏底部刻度栏
        // "header_chart_type", //隐藏k线样式选择
        "header_undo_redo", //隐藏撤销重做按钮
        "header_compare", //隐藏比较/增加商品按钮
        "adaptive_logo",
      ];
      // 移动端判定 - 去除左侧工具栏
      if(this.isMobile==1){
        disabled_features.push(...[
          'left_toolbar',
          "header_widget_dom_node",//隐藏头部组件
          "header_indicators", //隐藏指标按钮
          'control_bar',
          'source_selection_markers',
          'header_screenshot',
          'header_settings',//设置
          'border_around_the_chart',
          "remove_library_container_border"
        ])
      }

      this.widget = new TvWidget({
        // debug: true,
        fullscreen: false,// true
        autosize: true,
        symbol: symbol.toLocaleUpperCase(),
        interval: intervalMap[this.interval],
        container_id: "tv_chart_container",
        datafeed: this.datafeed,
        library_path: "./charting_library/",
        locale: //"zh",
					localStorage.getItem('lang') || 'zh',
        theme: //"Dark",//Light
          localStorage.getItem('theme')=="light"?"Light":"Dark",
        timezone: "Asia/Shanghai",
				toolbar_bg://"#1F232D",
          localStorage.getItem('theme')=="light"?"":"#1F232D",
        disabled_features: disabled_features,
        enabled_features: [
          "move_logo_to_main_pane",
          // "study_templates",
        ],
      });
    },
    // 切换商品
    setSymbol(symbol,web) {
      // this.unsubscribeKLine();

      // this.symbol = 'a_a';
      // this.exchange=web
      // var data=symbol+'_'+web
      // this.widget?.setSymbol(data, intervalMap[this.interval], () => {
      //   this.symbol = symbol;
      //   this.widget?.setSymbol(symbol, intervalMap[this.interval], () => {});
      //   // console.log("------setSymbol---------", this.exchange);
      // });

      if(this.exchange!=web){
        // 切换交易所
        this.exchange=web;
        this.symbol = symbol;

        this.initTradingView();
      }else{
        // 切换交易对
        this.symbol = symbol;
        if(this.widget){
          this.widget.setSymbol(symbol, intervalMap[this.interval], () => {
            // console.log("------setSymbol---------", this.exchange);
          });
        }
      }
    },
    // 轮询
    loopGet(){
      const symbol = this.symbol;
      const size = window.innerWidth;
      if(timeR3){
        clearInterval(timeR3)
      }
      timeR3=setInterval(()=>{
        // 交易所判定
        var since;
        if(
          this.exchange=='xt'||
          this.exchange=='lbank'||
          this.exchange=='mxc'||
          this.exchange=='bitmart'||
          this.exchange=='bidacoin'||
          this.exchange=='citex'
        ){
          since=this.since
        }else if(this.exchange=='gate'){
          since=size > 1000 ? 1000 : size
        }else if(this.exchange=='ok'){
          since=this.since||'';
        }else{
          // huobi、bian、biconomy、bitwell、bitrue、bibox、zt、mirrorx、bitget、coinw、hotcoin及其他
          since=size > 2000 ? 2000 : size
        }
        
        getKline({
          web: this.exchange,
          currency: symbol,
          account: this.account,
          k_type:this.interval,
          cb: since,
        }).then(res=>{
          // console.log('loop')
          // console.log(res)
          // 交易所判定
          if(this.exchange=='xt'){
            var data=res.result
            if(!!data&&!!data[0]&&data[0].t>this.newestTime){
              var item=data[0]
              this.newestTime=Number(item.t)
              this.datafeed.updateKLine({
                time: Number(item.t),
                open: Number(item.o),
                high: Number(item.h),
                low: Number(item.l),
                close: Number(item.c),
                volume: Number(item.v),
              });
            }
          }else if(this.exchange=='gate'){
            if(!!res[res.length-1]&&res[res.length-1][0]*1000>this.newestTime){
              var item=res[res.length-1]
              this.newestTime=item[0]*1000
              this.datafeed.updateKLine({
                time: item[0]*1000,
                open: Number(item[5]),
                high: Number(item[3]),
                low: Number(item[4]),
                close: Number(item[2]),
                volume: Number(item[6]),
              });
            }
          }else if(this.exchange=='huobi'){
            var data=res.data
            if(!!data&&!!data[0]&&data[0].id*1000>this.newestTime){
              var item=data[0]
              this.newestTime=item.id*1000
              this.datafeed.updateKLine({
                time: item.id * 1000,
                open: item.open,
                high: item.high,
                low: item.low,
                close: item.close,
                volume: item.vol,
              });
            }
          }else if(this.exchange=='lbank'){
            var data=res.data
            if(!!data&&!!data[data.length-1]&&data[data.length-1][0]*1000>this.newestTime){
              var item=data[data.length-1]
              this.newestTime=item[0]*1000
              this.datafeed.updateKLine({
                time: item[0]*1000,
                open: Number(item[1]),
                high: Number(item[2]),
                low: Number(item[3]),
                close: Number(item[4]),
                volume: Number(item[5]),
              });
            }
          }else if(
            this.exchange=='mxc'||
            this.exchange=='bidacoin'
          ){
            var data=res.data
            if(!!data&&!!data[data.length-1]&&data[data.length-1][0]*1000>this.newestTime){
              var item=data[data.length-1]
              this.newestTime=item[0]*1000
              this.datafeed.updateKLine({
                time: item[0]*1000,
                open: Number(item[1]),
                high: Number(item[3]),
                low: Number(item[4]),
                close: Number(item[2]),
                volume: Number(item[5]),
              });
            }
          }else if(
            this.exchange=='bian'||
            this.exchange=='biconomy'||
            this.exchange=='zt'
          ){
            if(!!res[res.length-1]&&res[res.length-1][0]>this.newestTime){
              var item=res[res.length-1]
              this.newestTime=item[0]
              this.datafeed.updateKLine({
                time: Number(item[0]),
                open: Number(item[1]),
                high: Number(item[2]),
                low: Number(item[3]),
                close: Number(item[4]),
                volume: Number(item[5]),
              });
            }
          }else if(this.exchange=='bitwell'){
            var data=res.data
            if(!!data&&!!data[0]&&data[0].time*1000>this.newestTime){
              var item=data[0]
              this.newestTime=item.time*1000
              this.datafeed.updateKLine({
                time: Number(item.time)*1000,
                open: Number(item.open),
                high: Number(item.high),
                low: Number(item.low),
                close: Number(item.close),
                volume: Number(item.volume),
              });
            }
          }else if(this.exchange=='bitrue'){
            var data=res.data
            if(!!data&&!!data[data.length-1]&&data[data.length-1].i*1000>this.newestTime){
              var item=data[data.length-1]
              this.newestTime=item.i*1000
              this.datafeed.updateKLine({
                time: item.i*1000,
                open: Number(item.o),
                high: Number(item.h),
                low: Number(item.l),
                close: Number(item.c),
                volume: Number(item.v),
              });
            }
          }else if(this.exchange=='bibox'){
            var data=res.result
            if(!!data&&!!data[data.length-1]&&data[data.length-1].time>this.newestTime){
              var item=data[data.length-1]
              this.newestTime=item.time
              this.datafeed.updateKLine({
                time: Number(item.time),
                open: Number(item.open),
                high: Number(item.high),
                low: Number(item.low),
                close: Number(item.close),
                volume: Number(item.vol),
              });
            }
          }else if(this.exchange=='mirrorx'){
            var data=res.tick
            if(!!data&&!!data[data.length-1]&&data[data.length-1].id*1000>this.newestTime){
              var item=data[data.length-1]
              this.newestTime=item.id*1000
              this.datafeed.updateKLine({
                time: item.id * 1000,
                open: item.open,
                high: item.high,
                low: item.low,
                close: item.close,
                volume: item.vol,
              });
            }
          }else if(this.exchange=='ok'){
            if(res.code=='0'){
              var data=res.data
              if(!!data&&!!data[0]&&data[0][0]>this.newestTime){
                var item=data[0]
                this.newestTime=item[0]
                this.datafeed.updateKLine({
                  time: item[0],
                  open: item[1],
                  high: item[2],
                  low: item[3],
                  close: item[4],
                  volume: item[6],
                });
              }
            }
          }else if(this.exchange=='bitget'){ 
            var data=res.data
            if(!!data&&!!data[data.length-1]&&data[data.length-1].ts>this.newestTime){
              var item=data[data.length-1]
              this.newestTime=item.ts
              this.datafeed.updateKLine({
                time: Number(item.ts),
                open: Number(item.open),
                high: Number(item.high),
                low: Number(item.low),
                close: Number(item.close),
                volume: Number(item.baseVol),
              });
            }
          }else if(this.exchange=='coinw'){ 
            var data=res.data
            if(!!data&&!!data[0]&&data[0].date>this.newestTime){
              var item=data[0]
              this.newestTime=item.date
              this.datafeed.updateKLine({
                time: Number(item.date),
                open: Number(item.open),
                high: Number(item.high),
                low: Number(item.low),
                close: Number(item.close),
                volume: Number(item.volume),
              });
            }
          }else if(this.exchange=='bitmart'){ 
            var data=res.data.klines
            if(!!data&&!!data[data.length-1]&&data[data.length-1].timestamp*1000>this.newestTime){
              var item=data[data.length-1]
              this.newestTime=item.timestamp*1000
              this.datafeed.updateKLine({
                time: Number(item.timestamp*1000),
                open: Number(item.open),
                high: Number(item.high),
                low: Number(item.low),
                close: Number(item.close),
                volume: Number(item.volume),
              });
            }
          }else if(this.exchange=='hotcoin'){
            var data=res.data
            if(!!data&&!!data[data.length-1]&&data[data.length-1][0]>this.newestTime){
              var item=data[data.length-1]
              this.newestTime=item[0]
              this.datafeed.updateKLine({
                time: Number(item[0]),
                open: Number(item[1]),
                high: Number(item[2]),
                low: Number(item[3]),
                close: Number(item[4]),
                volume: Number(item[5]),
              });
            }
          }else if(this.exchange=='citex'){
            // console.log(res)
            if(!!res[0]&&res[0][0]>this.newestTime){
              var item=res[0]
              this.newestTime=item[0]
              this.datafeed.updateKLine({
                time: Number(item[0]),
                open: Number(item[1]),
                high: Number(item[2]),
                low: Number(item[3]),
                close: Number(item[4]),
                volume: Number(item[5]),
              });
            }
          }else{
            if(!!res[res.length-1]&&res[res.length-1].time*1000>this.newestTime){
              var item=res[res.length-1]
              this.newestTime=item.time*1000
              this.datafeed.updateKLine({
                time: Number(item.time)*1000,
                open: Number(item.open),
                high: Number(item.high),
                low: Number(item.low),
                close: Number(item.close),
                volume: Number(item.volume),
              });
            }
          }
        })
      },60*1000)
    },
  },
  mounted() {
    this.initTradingView();
  },
  watch:{
    // device(newName,oldName){
    //   if(newName=='mobile'){
    //     this.widget.subscribe('toggle_sidebar')
    //   }
    // },
    lang(){
      this.initTradingView();
    },
    theme(){
      this.initTradingView();
    },
  },
  destroyed(){
    if(timeR3){
      clearInterval(timeR3)
      timeR3=''
    }
  }
};
</script>
<style scoped>
.kline{
  width: 100%;
  height: 100%;
}
.kline>div{
  width: 100%;
  height: 100%;
}
</style>