<template>
	<!-- 手动操盘 -->
	<div class="container">
		<!-- PC及适配-普通盘口 -->
		<template v-if="isMobile==0">
			<div class="header">
				<div class="current">
					<span class="openTitle">{{$t('common.currentOpen')}}：</span>
					<span class="exchange">{{exchange}}</span>
					<span class="pair" v-if="web!='opensea'">{{yenUnit1.toUpperCase()+'/'+yenUnit2.toUpperCase()}}</span>
					<span class="pair" v-else>{{yenUnit1.toUpperCase()}}</span>
				</div>
				<span class="relative">
					<span class="g_btn btn" @click="openPopCollect" :class="{novice1:novice==1&&helpNum==1}">{{$t('common.selectOpen')}}
					</span>
					<!-- 引导步骤1 -->
					<guidance v-if="novice==1&&helpNum==1" :position="position"></guidance>
				</span>
				<span class="collection" @click="toCollection">
					<span v-show="isCollection">
						<img src="@/assets/images/collection_active.png" alt="">
					</span>
					<span v-show="!isCollection">
						<img src="@/assets/images/collection.png" alt="">
					</span>
					{{$t('common.collection')}}
				</span>
			</div>
			
			<div class="row_flex">
				<div class="content_left">
					<div class="row_flex_inner">
						<div class="row_left" v-if="device!='mobile'">
							<div class="order_action">
								<div class="tabTit">
									<ul>
										<li :class="{active: tabIndex == 1}" @click="tabIndex = 1">{{$t('stocks.order')}}</li>
										<li :class="{active: tabIndex == 0}" @click="tabIndex = 0">{{(web_type==0?'CEX ':web_type==1?'DEX ':'NFT')+$t('stocks.tools')}}</li>
									</ul>
								</div>
								<div class="tabCon">
									<div class="order_action_tool" v-if="tabIndex == 0">
										<!-- cex/dex/nft量化工具 -->
										<div v-if="!isTool" class="list">
											<template v-if="web_type==0">
												<!-- cex量化工具 -->
												<div class="item" v-for="(item,index) in toolList" :key="index">
													<div class="left" :class="{notOpen:type<2}">
														<div class="title"><span class="icon"><img :src="require('@/assets/images/'+item+'.png')" /></span>{{$t('tools.'+item+'.name')}}</div>
														<div class="desc">{{$t('tools.'+item+'.sub')}}</div>
													</div>
													<div class="right">
														<div v-if="type>=2" class="btn" @click="handleCreate(item)">{{$t('common.into')}}</div>
														<div v-else class="btn" @click="handleOpen(item)">{{$t('common.open')}}</div>
													</div>
												</div>
											</template>
											<template v-else-if="web_type==1">
												<!-- dex子钱包管理入口 -->
												<div class="item">
													<div class="left" :class="{notOpen:type<4}">
														<div class="title"><span class="icon"><img :src="require('@/assets/images/wallet.png')" /></span>{{$t('tools.manage.name')}}</div>
														<div class="desc">{{$t('tools.manage.sub')}}</div>
													</div>
													<div class="right">
														<div v-if="type>=4" class="btn" @click="handleManage">{{$t('common.into')}}</div>
														<div v-else class="btn" @click="handleOpen(item)">{{$t('common.open')}}</div>
													</div>
												</div>
												<!-- dex量化工具 -->
												<div class="item" v-for="(item,index) in toolDexList" :key="'0' + index">
													<div class="left" :class="{notOpen:type<4}">
														<div class="title"><span class="icon"><img :src="require('@/assets/images/'+item+'.png')" /></span>{{$t('tools.'+item+'.name')}}</div>
														<div class="desc">{{$t('tools.'+item+'.sub')}}</div>
													</div>
													<div class="right">
														<div v-if="type>=4" class="btn" @click="handleCreate(item)">{{$t('common.into')}}</div>
														<div v-else class="btn" @click="handleOpen(item)">{{$t('common.open')}}</div>
													</div>
												</div>
											</template>
											<template v-else-if="web_type==2">
												<!-- nft钱包管理入口 -->
												<div class="item">
													<div class="left" :class="{notOpen:type<4}">
														<div class="title"><span class="icon"><img :src="require('@/assets/images/wallet.png')" /></span>{{$t('tools.manageNFT.name')}}</div>
														<div class="desc">{{$t('tools.manageNFT.sub')}}</div>
													</div>
													<div class="right">
														<div v-if="type>=4" class="btn" @click="handleManage">{{$t('common.into')}}</div>
														<div v-else class="btn" @click="handleOpen(item)">{{$t('common.open')}}</div>
													</div>
												</div>
												<!-- nft量化工具 -->
												<div class="item" v-for="(item,index) in toolNFTList" :key="index">
													<div class="left" :class="{notOpen:type<4}">
														<div class="title"><span class="icon"><img :src="require('@/assets/images/'+item+'.png')" /></span>{{$t('tools.'+item+'.name')}}</div>
														<div class="desc">{{$t('tools.'+item+'.sub')}}</div>
													</div>
													<div class="right">
														<div v-if="type>=2" class="btn" @click="handleCreate(item)">{{$t('common.into')}}</div>
														<div v-else class="btn" @click="handleOpen(item)">{{$t('common.open')}}</div>
													</div>
												</div>
											</template>
										</div>
										<!-- 工具表单 -->
										<div v-if="isTool" class="form">
											<div class="hd">
												<div class="left">
													<span class="icon"><img :src="require('@/assets/images/'+selectTool+'.png')" /></span>
													{{$t('tools.'+selectTool+'.name')}}
												</div>
												<div class="right">
													<div class="help" @click="handleHelp"><i class="iconfont icon-tips"></i>{{$t('common.description')}}</div>
													<div class="close" @click="handleClose"><i class="iconfont icon-x"></i></div>
												</div>
											</div>
											<div class="bd">
												<div class="action_form">
													<!-- 批量下买单/卖单 -->
													<div v-if="web!='opensea'&&(selectTool=='check'||selectTool=='sale')">
														<div class="title">{{selectTool=='check'?$t('stocks.check'):$t('stocks.sale')}}</div>
														<div class="inp_box">
															<el-input-number v-model="toolform.lowPrice" :step="0.01" controls-position="right" :placeholder="$t('common.minValue')" :min="0" class="inp" />
															~ <el-input-number v-model="toolform.highPrice" :step="0.01" controls-position="right" :placeholder="$t('common.maxValue')" :min="toolform.lowPrice||0" class="inp" />
														</div>
														<div class="title">{{$t('stocks.quantity')}}</div>
														<div class="inp_box">
															<el-input-number v-model="toolform.lowNum" :step="0.01" controls-position="right" :placeholder="$t('common.minValue')" :min="0" class="inp" />
															~ <el-input-number v-model="toolform.highNum" :step="0.01" controls-position="right" :placeholder="$t('common.maxValue')" :min="toolform.lowNum" class="inp" />
														</div>
														<div class="title">{{$t('stocks.total')}}</div>
														<div class="inp_box">
															<el-input-number v-model="toolform.totalNum" :step="1" controls-position="right" :placeholder="$t('common.max')+' 500'" :min="0" class="inp biginp" step-strictly />
														</div>
														<div class="title">{{$t('stocks.decimal')}}</div>
														<div class="inp_box">
															<el-input-number v-model="toolform.decimal" :step="1" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp biginp" step-strictly />
														</div>
													</div>
													<!-- 批量撤单 -->
													<div v-else-if="selectTool=='revoke'">
														<div class="title">{{$t('stocks.priceRange')}}</div>
														<div class="inp_box">
															<el-input-number v-model="toolform.lowPrice" :step="0.01" controls-position="right" :placeholder="$t('common.minValue')" :min="0" class="inp" />
															~ <el-input-number v-model="toolform.highPrice" :step="0.01" controls-position="right" :placeholder="$t('common.maxValue')" :min="toolform.lowPrice" class="inp" />
														</div>
														<div class="title">{{$t('stocks.quantityRange')}}</div>
														<div class="inp_box">
															<el-input-number v-model="toolform.lowNum" :step="0.01" controls-position="right" :placeholder="$t('common.minValue')" :min="0" class="inp" />
															~ <el-input-number v-model="toolform.highNum" :step="0.01" controls-position="right" :placeholder="$t('common.maxValue')" :min="toolform.lowNum" class="inp" />
														</div>
													</div>
													<!-- dex -->
													<div v-else-if="selectTool=='calculate'">
														<div class="title">{{$t('stocks.target')}}</div>
														<div class="inp_box">
															<el-input-number v-model="toolform.target" :step="0.01" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp biginp" />
															<span class="company green">{{yenUnit2}}</span>
														</div>
													</div>
													<div v-else-if="selectTool=='transaction'">

														<div class="title">{{$t('stocks.strategy')}}</div>
														<div class="radio_box">
															<el-radio v-model="toolform.strategy" label="1">{{$t('stocks.strategyDefault')}}</el-radio>
															<el-radio v-model="toolform.strategy" label="2">{{$t('stocks.strategyCondition')}}</el-radio>
														</div>
														
														<div class="title" v-if="toolform.strategy==2">{{$t('stocks.walletsNumber')}}</div>
														<div class="inp_box" v-if="toolform.strategy==2">
															<el-input-number v-model="toolform.walletsNumber" :step="1" controls-position="right" :placeholder="$t('common.value')" :min="1" class="inp biginp" />
															<span class="company"></span>
														</div>

														<div class="title">{{$t('stocks.volume')}}</div>
														<div class="inp_box">
															<el-input-number v-model="toolform.volume" :step="0.01" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp biginp" />
															<span class="company" :class="toolform.transactionType==1?'green':'red'">{{!toolform.transactionType?'':toolform.transactionType==1?yenUnit2:yenUnit1}}</span>
														</div>
														
														<div class="title" v-if="toolform.strategy==2">{{$t('stocks.floatingRange')}}</div>
														<div class="inp_box" v-if="toolform.strategy==2" id="float">
															<el-input-number @input.native="floatChange" v-model="toolform.floatingRange" :step="0.01" controls-position="right" :placeholder="$t('common.value')" :min="0" :max="80" class="inp biginp" />
															<span class="company util">%</span>
														</div>
                    									<div class="tip" v-if="is0">{{$t('stocks.floatTip0')}}</div>

														<div class="title">{{$t('stocks.transactionType')}}</div>
														<div class="radio_box">
															<el-radio v-model="toolform.transactionType" label="1">{{$t('stocks.buy')}}</el-radio>
															<el-radio v-model="toolform.transactionType" label="0">{{$t('stocks.sell')}}</el-radio>
														</div>
													</div>
													<div v-else-if="selectTool=='empower'">
														<div class="title">{{$t('stocks.token')}}</div>
														<div class="inp_box">
															<input v-model="toolform.token"  controls-position="right" :placeholder="$t('stocks.token')" class="inp biginp chain" />
														</div>
														<!-- <div class="title">{{$t('stocks.walletType')}}</div>
														<div class="inp_box">
															<el-select v-model="toolform.walletType" :placeholder="$t('common.select')">
																<el-option
																:label="$t('stocks.mainWallet')"
																value="1">
																</el-option>
																<el-option
																:label="$t('stocks.subWallet')"
																value="0">
																</el-option>
															</el-select>
														</div> -->
													</div>
													<!-- nft 批量下买单/卖单 -->
													<!-- 交易数量、买单价格范围 -->
													<div v-else-if="web=='opensea'&&(selectTool=='check'||selectTool=='sale')">
														<div class="title">{{selectTool=='check'?$t('stocks.check'):$t('stocks.sale')}}</div>
														<div class="inp_box">
															<el-input-number v-model="toolform.lowPrice" :step="0.01" controls-position="right" :placeholder="$t('common.minValue')" :min="0" class="inp" />
															~ <el-input-number v-model="toolform.highPrice" :step="0.01" controls-position="right" :placeholder="$t('common.maxValue')" :min="toolform.lowPrice||0" class="inp" />
														</div>
														<div class="title">{{$t('stocks.quantity')}}</div>
														<div class="inp_box">
															<el-input-number v-model="toolform.lowNum" :step="0.01" controls-position="right" :placeholder="$t('common.minValue')" :min="0" class="inp" />
															~ <el-input-number v-model="toolform.highNum" :step="0.01" controls-position="right" :placeholder="$t('common.maxValue')" :min="toolform.lowNum" class="inp" />
														</div>
														<!-- <div class="title">{{$t('stocks.total')}}</div>
														<div class="inp_box">
															<el-input-number v-model="toolform.totalNum" :step="1" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp biginp" step-strictly />
														</div>
														<div class="title">{{$t('stocks.decimal')}}</div>
														<div class="inp_box">
															<el-input-number v-model="toolform.decimal" :step="1" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp biginp" step-strictly />
														</div> -->
													</div>
													<!-- 钱包数据 -->
													<div class="balanceData">
														<template v-if="web!='opensea'">
															<!-- 主钱包 -->
															<div class="bigtitle">{{$t('stocks.mainAssets')}}</div>	
															<div>
																<div>
																	<div class="title">{{yenUnit1.toUpperCase()}} </div>
																	<div class="money">
																		<span class="green">
																			{{$t('common.available')}} 
																			{{
																				balance1.balance?
																				Math.floor(balance1.balance * 1000000) / 1000000:
																				balance1.balance
																			}}
																		</span>
																		<span class="red">{{$t('common.frozen')}} {{balance1.freeze?Math.floor(balance1.freeze * 1000000) / 1000000:balance1.freeze}}</span>
																	</div>
																</div>
																<div>
																	<div class="title">{{yenUnit2.toUpperCase()}} </div>
																	<div class="money">
																		<span class="green">{{$t('common.available')}} {{balance2.balance?Math.floor(balance2.balance * 1000000) / 1000000:balance2.balance}}</span>
																		<span class="red">{{$t('common.frozen')}} {{balance2.freeze?Math.floor(balance2.freeze * 1000000) / 1000000:balance2.freeze}}</span>
																	</div>
																</div>
															</div>
															<!-- 子钱包 -->
															<template v-if="Object.keys(subBalance).length>0">
																<div class="bigtitle">{{$t('stocks.subAssets')}}</div>	
																<div>
																	<div v-for="(item,key,index) in subBalance" :key="index">
																		<div class="title">{{key}} </div>
																		<div class="money">
																			<span class="green">{{$t('common.available')}}  {{item?Math.floor(item * 1000000) / 1000000:item}}</span>
																			<span class="red">{{$t('common.frozen')}}  0</span>
																		</div>
																	</div>
																</div>
															</template>
														</template>
														<template v-else>
															<!-- opensea -->
															<template v-if="Object.keys(balance1).length>0">
																<div class="bigtitle">{{$t('stocks.mainAssets')}}</div>	
																<div>
																	<div v-for="(item,key,index) in balance1" :key="index">
																		<div class="title">{{key}} </div>
																		<div class="money">
																			<span class="green">{{$t('common.available')}}  {{item?Math.floor(item * 1000000) / 1000000:item}}</span>
																			<!-- {{balance1.freeze?Math.floor(balance1.freeze * 1000000) / 1000000:balance1.freeze}} -->
																			<span class="red">{{$t('common.frozen')}}  0</span>
																		</div>
																	</div>
																</div>
															</template>
															<template v-if="Object.keys(subBalance).length>0">
																<div class="bigtitle">{{$t('stocks.subAssets')}}</div>	
																<div>
																	<div v-for="(item,key,index) in subBalance" :key="index">
																		<div class="title">{{key}} </div>
																		<div class="money">
																			<span class="green">{{$t('common.available')}}  {{item?Math.floor(item * 1000000) / 1000000:item}}</span>
																			<span class="red">{{$t('common.frozen')}}  0</span>
																		</div>
																	</div>
																</div>
															</template>

															<template v-if="Object.keys(balance2).length>0">
																<div class="bigtitle">{{$t('stocks.mainAssetsNFT')}}</div>	
																<div>
																	<div v-for="(item,key,index) in balance2" :key="index">
																		<div class="title">{{key}} </div>
																		<div class="money">
																			<span class="green">{{$t('common.available')}}  {{item?Math.floor(item * 1000000) / 1000000:item}}</span>
																			<!-- {{balance1.freeze?Math.floor(balance1.freeze * 1000000) / 1000000:balance1.freeze}} -->
																			<span class="red">{{$t('common.frozen')}}  0</span>
																		</div>
																	</div>
																</div>
															</template>
															<template v-if="web=='opensea'&&Object.keys(subBalance2).length>0">
																<div class="bigtitle">{{$t('stocks.subAssetsNFT')}}</div>	
																<div>
																	<div v-for="(item,key,index) in subBalance2" :key="index">
																		<div class="title">{{key}} </div>
																		<div class="money">
																			<span class="green">{{$t('common.available')}}  {{item?Math.floor(item * 1000000) / 1000000:item}}</span>
																			<span class="red">{{$t('common.frozen')}}  0</span>
																		</div>
																	</div>
																</div>
															</template>
														</template>

													</div>
													<div class="bottom">
														<div class="g_btn btn bigbtn" @click="submitOrder">{{$t('common.submit')}}</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<!-- 手动下单 -->
									<div class="order_action_handle" v-if="tabIndex == 1">
										<div class="hd" v-if="web_type==1">
											<div class="left">
												<!-- <span class="icon">
													<img :src="require('@/assets/images/'+selectTool+'.png')" />
												</span> -->
												{{$t('stocks.mainOrder')}}
											</div>
											<div class="right">
												<div class="help" @click="handleHelp2">
													<i class="iconfont icon-tips"></i>
													{{$t('common.description')}}
												</div>
											</div>
										</div>
										<!-- <div class="dex_order_tip">
											{{$t('stocks.orderTip')}}
										</div> -->
										<div class="action_form">
											<div class="title">{{$t('common.pirce')}}</div>
											<div class="inp_box">
												<el-input-number v-model="form.price" :step="0.01" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp biginp" />
											</div>
											<div class="title">{{$t('common.num')}}</div>
											<div class="inp_box">
												<el-input-number v-model="form.number" :step="0.01" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp biginp" />
											</div>
											<div class="balanceData">
												<template v-if="web!='opensea'">
													<!-- 主钱包 -->
													<div class="bigtitle">{{$t('stocks.mainAssets')}}</div>	
													<div>
														<div>
															<div class="title">{{yenUnit1.toUpperCase()}} </div>
															<div class="money">
																<span class="green">{{$t('common.available')}} {{balance1.balance?Math.floor(balance1.balance * 1000000) / 1000000:balance1.balance}}</span>
																<span class="red">{{$t('common.frozen')}} {{balance1.freeze?Math.floor(balance1.freeze * 1000000) / 1000000:balance1.freeze}}</span>
															</div>
														</div>
														<div>
															<div class="title">{{yenUnit2.toUpperCase()}} </div>
															<div class="money">
																<span class="green">{{$t('common.available')}} {{balance2.balance?Math.floor(balance2.balance * 1000000) / 1000000:balance2.balance}}</span>
																<span class="red">{{$t('common.frozen')}} {{balance2.freeze?Math.floor(balance2.freeze * 1000000) / 1000000:balance2.freeze}}</span>
															</div>
														</div>
													</div>
													<!-- 子钱包 -->
													<template v-if="Object.keys(subBalance).length>0">
														<div class="bigtitle">{{$t('stocks.subAssets')}}</div>	
														<div>
															<div v-for="(item,key,index) in subBalance" :key="index">
																<div class="title">{{key}} </div>
																<div class="money">
																	<span class="green">{{$t('common.available')}}  {{item?Math.floor(item * 1000000) / 1000000:item}}</span>
																	<span class="red">{{$t('common.frozen')}}  0</span>
																</div>
															</div>
														</div>
													</template>
												</template>
												<template v-else>
													<!-- opensea -->
													<template v-if="Object.keys(balance1).length>0">
														<div class="bigtitle">{{$t('stocks.mainAssets')}}</div>	
														<div>
															<div v-for="(item,key,index) in balance1" :key="index">
																<div class="title">{{key}} </div>
																<div class="money">
																	<span class="green">{{$t('common.available')}}  {{item?Math.floor(item * 1000000) / 1000000:item}}</span>
																	<!-- {{balance1.freeze?Math.floor(balance1.freeze * 1000000) / 1000000:balance1.freeze}} -->
																	<span class="red">{{$t('common.frozen')}}  0</span>
																</div>
															</div>
														</div>
													</template>
													<template v-if="Object.keys(subBalance).length>0">
														<div class="bigtitle">{{$t('stocks.subAssets')}}</div>	
														<div>
															<div v-for="(item,key,index) in subBalance" :key="index">
																<div class="title">{{key}} </div>
																<div class="money">
																	<span class="green">{{$t('common.available')}}  {{item?Math.floor(item * 1000000) / 1000000:item}}</span>
																	<span class="red">{{$t('common.frozen')}}  0</span>
																</div>
															</div>
														</div>
													</template>

													<template v-if="Object.keys(balance2).length>0">
														<div class="bigtitle">{{$t('stocks.mainAssetsNFT')}}</div>	
														<div>
															<div v-for="(item,key,index) in balance2" :key="index">
																<div class="title">{{key}} </div>
																<div class="money">
																	<span class="green">{{$t('common.available')}}  {{item?Math.floor(item * 1000000) / 1000000:item}}</span>
																	<!-- {{balance1.freeze?Math.floor(balance1.freeze * 1000000) / 1000000:balance1.freeze}} -->
																	<span class="red">{{$t('common.frozen')}}  0</span>
																</div>
															</div>
														</div>
													</template>
													<template v-if="web=='opensea'&&Object.keys(subBalance2).length>0">
														<div class="bigtitle">{{$t('stocks.subAssetsNFT')}}</div>	
														<div>
															<div v-for="(item,key,index) in subBalance2" :key="index">
																<div class="title">{{key}} </div>
																<div class="money">
																	<span class="green">{{$t('common.available')}}  {{item?Math.floor(item * 1000000) / 1000000:item}}</span>
																	<span class="red">{{$t('common.frozen')}}  0</span>
																</div>
															</div>
														</div>
													</template>
												</template>
											</div>
											<div class="bottom">
												<div class="g_btn btn" @click="handleOrder(1)">{{$t('common.purchase')}}</div>
												<div class="g_btn btn red" @click="handleOrder(0)">{{$t('common.sellout')}}</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="row_right" v-if="web!='opensea'">
							<KLineWidget
								:web="web"
								:pair="symbol"
								ref="kLineWidget"
							></KLineWidget>
						</div>
						<!-- opensea -->
						<div class="row_right opensea" v-else>
							<div class="img" v-if="!!nftInfo.collection_image">
								<img :src="nftInfo.collection_image" alt="">
							</div>
							<div class="img notImage" v-else>
								<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 532 532" fill="none">
									<path d="M292 26.5581L460.363 123.762C476.452 133.051 486.363 150.218 486.363 168.796V363.204C486.363 381.782 476.452 398.949 460.363 408.238L292 505.442C275.911 514.731 256.089 514.731 240 505.442L71.6372 408.238C55.5484 398.949 45.6372 381.782 45.6372 363.204V168.796C45.6372 150.218 55.5484 133.051 71.6372 123.762L240 26.5581C256.089 17.2692 275.911 17.2692 292 26.5581Z" stroke="#96A0AB" stroke-width="20"/>
									<path d="M102.592 372C96.1973 372 93 369.802 93 365.406V167.594C93 163.198 96.1973 161 102.592 161H108.65C111.174 161 113.194 161.5 114.708 162.499C116.223 163.298 117.485 164.796 118.494 166.994L177.056 300.967C179.412 306.162 181.599 312.456 183.619 319.849L184.881 319.25C183.534 312.257 182.861 305.663 182.861 299.469V167.594C182.861 163.198 186.059 161 192.453 161H195.482C201.877 161 205.074 163.198 205.074 167.594V365.406C205.074 369.802 201.877 372 195.482 372H190.182C185.47 372 182.441 370.501 181.094 367.504L121.019 229.036C118.663 223.84 116.475 217.546 114.456 210.153L113.194 210.753C114.54 217.746 115.213 224.34 115.213 230.534V365.406C115.213 369.802 112.016 372 105.621 372H102.592Z" fill="#96A0AB"/>
									<path d="M252.742 372C246.348 372 243.15 369.802 243.15 365.406V170.591C243.15 167.394 243.655 165.096 244.665 163.697C245.843 162.299 247.61 161.599 249.966 161.599H315.595C318.792 161.599 320.391 164.597 320.391 170.591V175.386C320.391 181.381 318.792 184.378 315.595 184.378H265.868V258.707H311.303C314.501 258.707 316.099 261.705 316.099 267.699V272.494C316.099 278.489 314.501 281.486 311.303 281.486H265.868V365.406C265.868 369.802 262.671 372 256.276 372H252.742Z" fill="#96A0AB"/>
									<path d="M385.982 372C379.588 372 376.39 369.802 376.39 365.406V184.378H340.042C336.845 184.378 335.246 181.381 335.246 175.386V170.591C335.246 164.597 336.845 161.599 340.042 161.599H434.952C436.803 161.599 438.065 162.299 438.738 163.697C439.579 165.096 440 167.394 440 170.591V175.386C440 178.583 439.579 180.881 438.738 182.28C438.065 183.679 436.803 184.378 434.952 184.378H399.108V365.406C399.108 369.802 395.911 372 389.516 372H385.982Z" fill="#96A0AB"/>
								</svg>
							</div>
							<div class="detail_box">
								<div class="title">{{nftInfo.collection_name}}</div>
								<div>
									<div class="label">{{$t('stocks.contractAddress')}}: </div>
									<el-tooltip class="item" effect="dark" :content="$t('common.copy')" placement="top" :enterable="false">
										<div class="value value2 copyBtn" @click="handleCopy">
												{{nftInfo.contract_address}}
										</div>
									</el-tooltip>
								</div>
								<div class="sub" :class="{detail:!more}">{{nftInfo.description}}</div>
								<div class="btn" v-if="more==false" @click="more=true">{{$t('stocks.more')}}</div>
								<div class="btn" v-else @click="more=false">{{$t('stocks.less')}}</div>
								<div>
									<div class="label">{{$t('stocks.date')}}: </div>
									<div class="value">{{nftInfo.created_date}}</div>
								</div>
								<div>
									<div class="label">{{$t('stocks.items')}}: </div>
									<div class="value">{{nftInfo.items}}</div>
								</div>
								<div>
									<div class="label">{{$t('stocks.owners')}}: </div>
									<div class="value">{{nftInfo.owners}}</div>
								</div>
								<div>
									<div class="label">{{$t('stocks.totalVolume')}}: </div>
									<div class="value">
										<div class="currency_icon" v-if="!!nftInfo.currency_image">
											<img :src="nftInfo.currency_image" alt="">
										</div>
										{{nftInfo.total_volume}}
									</div>
								</div>
								<div>
									<div class="label">{{$t('stocks.floor')}}: 
									</div>
									<div class="value">
										<div class="currency_icon" v-if="!!nftInfo.currency_image">
											<img :src="nftInfo.currency_image" alt="">
										</div>
										{{nftInfo.floor_price}}
									</div>
								</div>
								<div>
									<div class="label">{{$t('stocks.average')}}: </div>
									<div class="value">
										<div class="currency_icon" v-if="!!nftInfo.currency_image">
											<img :src="nftInfo.currency_image" alt="">
										</div>
										{{nftInfo.average_price}}
									</div>
								</div>
								<div>
									<div class="label">{{$t('stocks.currency')}}: </div>
									<div class="value">{{nftInfo.currency}}</div>
								</div>
							</div>
						</div>
					</div>
					<!-- 小屏 工具-盘口 -->
					<div class="row_flex_inner row_flex_inner2" v-if="device=='mobile'">
						<!-- 小屏 量化工具 -->
						<div class="row_left">
							<div class="order_action">
								<div class="tabTit">
									<ul>
										<li :class="{active: tabIndex == 1}" @click="tabIndex = 1">{{$t('stocks.order')}}</li>
										<li :class="{active: tabIndex == 0}" @click="tabIndex = 0">{{(web_type==0?'CEX ':web_type==1?'DEX ':'NFT')+$t('stocks.tools')}}</li>
									</ul>
								</div>
								<div class="tabCon">
									<div class="order_action_tool" v-if="tabIndex == 0">
										<div v-if="!isTool" class="list">
											<template v-if="web_type==0">
												<!-- cex量化工具 -->
												<div class="item" v-for="(item,index) in toolList" :key="index">
													<div class="left" :class="{notOpen:type<2}">
														<div class="title"><span class="icon"><img :src="require('@/assets/images/'+item+'.png')" /></span>{{$t('tools.'+item+'.name')}}</div>
														<div class="desc">{{$t('tools.'+item+'.sub')}}</div>
													</div>
													<div class="right">
														<div v-if="type>=2" class="btn" @click="handleCreate(item)">{{$t('common.into')}}</div>
														<div v-else class="btn" @click="handleOpen(item)">{{$t('common.open')}}</div>
													</div>
												</div>
											</template>
											<template v-else-if="web_type==1">
												<!-- dex子钱包管理入口 -->
												<div class="item">
													<div class="left" :class="{notOpen:type<4}">
														<div class="title"><span class="icon"><img :src="require('@/assets/images/wallet.png')" /></span>{{$t('tools.manage.name')}}</div>
														<div class="desc">{{$t('tools.manage.sub')}}</div>
													</div>
													<div class="right">
														<div v-if="type>=4" class="btn" @click="handleManage">{{$t('common.into')}}</div>
														<div v-else class="btn" @click="handleOpen(item)">{{$t('common.open')}}</div>
													</div>
												</div>
												<!-- dex量化工具 -->
												<div class="item" v-for="(item,index) in toolDexList" :key="'0' + index">
													<div class="left" :class="{notOpen:type<4}">
														<div class="title"><span class="icon"><img :src="require('@/assets/images/'+item+'.png')" /></span>{{$t('tools.'+item+'.name')}}</div>
														<div class="desc">{{$t('tools.'+item+'.sub')}}</div>
													</div>
													<div class="right">
														<div v-if="type>=4" class="btn" @click="handleCreate(item)">{{$t('common.into')}}</div>
														<div v-else class="btn" @click="handleOpen(item)">{{$t('common.open')}}</div>
													</div>
												</div>
											</template>
											<template v-else-if="web_type==2">
												<!-- nft钱包管理入口 -->
												<div class="item">
													<div class="left" :class="{notOpen:type<4}">
														<div class="title"><span class="icon"><img :src="require('@/assets/images/wallet.png')" /></span>{{$t('tools.manageNFT.name')}}</div>
														<div class="desc">{{$t('tools.manageNFT.sub')}}</div>
													</div>
													<div class="right">
														<div v-if="type>=4" class="btn" @click="handleManage">{{$t('common.into')}}</div>
														<div v-else class="btn" @click="handleOpen(item)">{{$t('common.open')}}</div>
													</div>
												</div>
												<!-- nft量化工具 -->
												<div class="item" v-for="(item,index) in toolNFTList" :key="index">
													<div class="left" :class="{notOpen:type<4}">
														<div class="title"><span class="icon"><img :src="require('@/assets/images/'+item+'.png')" /></span>{{$t('tools.'+item+'.name')}}</div>
														<div class="desc">{{$t('tools.'+item+'.sub')}}</div>
													</div>
													<div class="right">
														<div v-if="type>=2" class="btn" @click="handleCreate(item)">{{$t('common.into')}}</div>
														<div v-else class="btn" @click="handleOpen(item)">{{$t('common.open')}}</div>
													</div>
												</div>
											</template>
										</div>
										<div v-if="isTool" class="form">
											<div class="hd">
												<div class="left">
													<span class="icon"><img :src="require('@/assets/images/'+selectTool+'.png')" /></span>
													{{$t('tools.'+selectTool+'.name')}}
												</div>
												<div class="right">
													<div class="help" @click="handleHelp"><i class="iconfont icon-tips"></i>{{$t('common.description')}}</div>
													<div class="close" @click="handleClose"><i class="iconfont icon-x"></i></div>
												</div>
											</div>
											<div class="bd">
												<div class="action_form">
													<!-- 批量下买单/卖单 -->
													<div v-if="web!='opensea'&&(selectTool=='check'||selectTool=='sale')">
														<div class="title">{{selectTool=='check'?$t('stocks.check'):$t('stocks.sale')}}</div>
														<div class="inp_box">
															<el-input-number v-model="toolform.lowPrice" :step="0.01" controls-position="right" :placeholder="$t('common.minValue')" :min="0" class="inp" />
															~ <el-input-number v-model="toolform.highPrice" :step="0.01" controls-position="right" :placeholder="$t('common.maxValue')" :min="toolform.lowPrice" class="inp" />
														</div>
														<div class="title">{{$t('stocks.quantity')}}</div>
														<div class="inp_box">
															<el-input-number v-model="toolform.lowNum" :step="0.01" controls-position="right" :placeholder="$t('common.minValue')" :min="0" class="inp" />
															~ <el-input-number v-model="toolform.highNum" :step="0.01" controls-position="right" :placeholder="$t('common.maxValue')" :min="toolform.lowNum" class="inp" />
														</div>
														<div class="title">{{$t('stocks.total')}}</div>
														<div class="inp_box">
															<el-input-number v-model="toolform.totalNum" :step="1" controls-position="right" :placeholder="$t('common.max')+' 500'" :min="0" class="inp biginp" step-strictly />
														</div>
														<div class="title">{{$t('stocks.decimal')}}</div>
														<div class="inp_box">
															<el-input-number v-model="toolform.decimal" :step="1" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp biginp" step-strictly />
														</div>
													</div>
													<!-- 批量撤单 -->
													<div v-else-if="selectTool=='revoke'">
														<div class="title">{{$t('stocks.priceRange')}}</div>
														<div class="inp_box">
															<el-input-number v-model="toolform.lowPrice" :step="0.01" controls-position="right" :placeholder="$t('common.minValue')" :min="0" class="inp" />
															~ <el-input-number v-model="toolform.highPrice" :step="0.01" controls-position="right" :placeholder="$t('common.maxValue')" :min="toolform.lowPrice" class="inp" />
														</div>
														<div class="title">{{$t('stocks.quantityRange')}}</div>
														<div class="inp_box">
															<el-input-number v-model="toolform.lowNum" :step="0.01" controls-position="right" :placeholder="$t('common.minValue')" :min="0" class="inp" />
															~ <el-input-number v-model="toolform.highNum" :step="0.01" controls-position="right" :placeholder="$t('common.maxValue')" :min="toolform.lowNum" class="inp" />
														</div>
													</div>

													<div v-else-if="selectTool=='calculate'">
														<div class="title">{{$t('stocks.target')}}</div>
														<div class="inp_box">
															<el-input-number v-model="toolform.target" :step="0.01" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp biginp" />
															<span class="company green">{{yenUnit2}}</span>
														</div>
													</div>
													<div v-else-if="selectTool=='transaction'">

														<div class="title">{{$t('stocks.strategy')}}</div>
														<div class="radio_box">
															<el-radio v-model="toolform.strategy" label="1">{{$t('stocks.strategyDefault')}}</el-radio>
															<el-radio v-model="toolform.strategy" label="2">{{$t('stocks.strategyCondition')}}</el-radio>
														</div>
														
														<div class="title" v-if="toolform.strategy==2">{{$t('stocks.walletsNumber')}}</div>
														<div class="inp_box" v-if="toolform.strategy==2">
															<el-input-number v-model="toolform.walletsNumber" :step="1" controls-position="right" :placeholder="$t('common.value')" :min="1" class="inp biginp" />
															<span class="company"></span>
														</div>

														<div class="title">{{$t('stocks.volume')}}</div>
														<div class="inp_box">
															<el-input-number v-model="toolform.volume" :step="0.01" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp biginp" />
															<span class="company" :class="toolform.transactionType==1?'green':'red'">{{!toolform.transactionType?'':toolform.transactionType==1?yenUnit2:yenUnit1}}</span>
														</div>
														
														<div class="title" v-if="toolform.strategy==2">{{$t('stocks.floatingRange')}}</div>
														<div class="inp_box" v-if="toolform.strategy==2" id="float">
															<el-input-number @input.native="floatChange" v-model="toolform.floatingRange" :step="0.01" controls-position="right" :placeholder="$t('common.value')" :min="0" :max="80" class="inp biginp" />
															<span class="company util">%</span>
														</div>
                    									<div class="tip" v-if="is0">{{$t('stocks.floatTip0')}}</div>

														<div class="title">{{$t('stocks.transactionType')}}</div>
														<div class="radio_box">
															<el-radio v-model="toolform.transactionType" label="1">{{$t('stocks.buy')}}</el-radio>
															<el-radio v-model="toolform.transactionType" label="0">{{$t('stocks.sell')}}</el-radio>
														</div>
													</div>
													<div v-else-if="selectTool=='empower'">
														<div class="title">{{$t('stocks.token')}}</div>
														<div class="inp_box">
															<input v-model="toolform.token"  controls-position="right" :placeholder="$t('stocks.token')" class="inp biginp chain" />
														</div>
														<!-- <div class="title">{{$t('stocks.walletType')}}</div>
														<div class="inp_box">
															<el-select v-model="toolform.walletType" :placeholder="$t('common.select')">
																<el-option
																:label="$t('stocks.mainWallet')"
																value="1">
																</el-option>
																<el-option
																:label="$t('stocks.subWallet')"
																value="0">
																</el-option>
															</el-select>
														</div> -->
													</div>
													<!-- nft 批量下买单/卖单 -->
													<!-- 交易数量、买单价格范围 -->
													<div v-else-if="web=='opensea'&&(selectTool=='check'||selectTool=='sale')">
														<div class="title">{{selectTool=='check'?$t('stocks.check'):$t('stocks.sale')}}</div>
														<div class="inp_box">
															<el-input-number v-model="toolform.lowPrice" :step="0.01" controls-position="right" :placeholder="$t('common.minValue')" :min="0" class="inp" />
															~ <el-input-number v-model="toolform.highPrice" :step="0.01" controls-position="right" :placeholder="$t('common.maxValue')" :min="toolform.lowPrice||0" class="inp" />
														</div>
														<div class="title">{{$t('stocks.quantity')}}</div>
														<div class="inp_box">
															<el-input-number v-model="toolform.lowNum" :step="0.01" controls-position="right" :placeholder="$t('common.minValue')" :min="0" class="inp" />
															~ <el-input-number v-model="toolform.highNum" :step="0.01" controls-position="right" :placeholder="$t('common.maxValue')" :min="toolform.lowNum" class="inp" />
														</div>
														<!-- <div class="title">{{$t('stocks.total')}}</div>
														<div class="inp_box">
															<el-input-number v-model="toolform.totalNum" :step="1" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp biginp" step-strictly />
														</div>
														<div class="title">{{$t('stocks.decimal')}}</div>
														<div class="inp_box">
															<el-input-number v-model="toolform.decimal" :step="1" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp biginp" step-strictly />
														</div> -->
													</div>
													<!-- 钱包数据 -->
													<div class="balanceData">
														<template v-if="web!='opensea'">
															<!-- 主钱包 -->
															<div class="bigtitle">{{$t('stocks.mainAssets')}}</div>	
															<div>
																<div>
																	<div class="title">{{yenUnit1.toUpperCase()}} </div>
																	<div class="money">
																		<span class="green">{{$t('common.available')}} {{balance1.balance?Math.floor(balance1.balance * 1000000) / 1000000:balance1.balance}}</span>
																		<span class="red">{{$t('common.frozen')}} {{balance1.freeze?Math.floor(balance1.freeze * 1000000) / 1000000:balance1.freeze}}</span>
																	</div>
																</div>
																<div>
																	<div class="title">{{yenUnit2.toUpperCase()}} </div>
																	<div class="money">
																		<span class="green">{{$t('common.available')}} {{balance2.balance?Math.floor(balance2.balance * 1000000) / 1000000:balance2.balance}}</span>
																		<span class="red">{{$t('common.frozen')}} {{balance2.freeze?Math.floor(balance2.freeze * 1000000) / 1000000:balance2.freeze}}</span>
																	</div>
																</div>
															</div>
															<!-- 子钱包 -->
															<template v-if="Object.keys(subBalance).length>0">
																<div class="bigtitle">{{$t('stocks.subAssets')}}</div>	
																<div>
																	<div v-for="(item,key,index) in subBalance" :key="index">
																		<div class="title">{{key}} </div>
																		<div class="money">
																			<span class="green">{{$t('common.available')}}  {{item?Math.floor(item * 1000000) / 1000000:item}}</span>
																			<span class="red">{{$t('common.frozen')}}  0</span>
																		</div>
																	</div>
																</div>
															</template>
														</template>
														<template v-else>
															<!-- opensea -->
															<template v-if="Object.keys(balance1).length>0">
																<div class="bigtitle">{{$t('stocks.mainAssets')}}</div>	
																<div>
																	<div v-for="(item,key,index) in balance1" :key="index">
																		<div class="title">{{key}} </div>
																		<div class="money">
																			<span class="green">{{$t('common.available')}}  {{item?Math.floor(item * 1000000) / 1000000:item}}</span>
																			<!-- {{balance1.freeze?Math.floor(balance1.freeze * 1000000) / 1000000:balance1.freeze}} -->
																			<span class="red">{{$t('common.frozen')}}  0</span>
																		</div>
																	</div>
																</div>
															</template>
															<template v-if="Object.keys(subBalance).length>0">
																<div class="bigtitle">{{$t('stocks.subAssets')}}</div>	
																<div>
																	<div v-for="(item,key,index) in subBalance" :key="index">
																		<div class="title">{{key}} </div>
																		<div class="money">
																			<span class="green">{{$t('common.available')}}  {{item?Math.floor(item * 1000000) / 1000000:item}}</span>
																			<span class="red">{{$t('common.frozen')}}  0</span>
																		</div>
																	</div>
																</div>
															</template>

															<template v-if="Object.keys(balance2).length>0">
																<div class="bigtitle">{{$t('stocks.mainAssetsNFT')}}</div>	
																<div>
																	<div v-for="(item,key,index) in balance2" :key="index">
																		<div class="title">{{key}} </div>
																		<div class="money">
																			<span class="green">{{$t('common.available')}}  {{item?Math.floor(item * 1000000) / 1000000:item}}</span>
																			<!-- {{balance1.freeze?Math.floor(balance1.freeze * 1000000) / 1000000:balance1.freeze}} -->
																			<span class="red">{{$t('common.frozen')}}  0</span>
																		</div>
																	</div>
																</div>
															</template>
															<template v-if="web=='opensea'&&Object.keys(subBalance2).length>0">
																<div class="bigtitle">{{$t('stocks.subAssetsNFT')}}</div>	
																<div>
																	<div v-for="(item,key,index) in subBalance2" :key="index">
																		<div class="title">{{key}} </div>
																		<div class="money">
																			<span class="green">{{$t('common.available')}}  {{item?Math.floor(item * 1000000) / 1000000:item}}</span>
																			<span class="red">{{$t('common.frozen')}}  0</span>
																		</div>
																	</div>
																</div>
															</template>
														</template>
													</div>
													<div class="bottom">
														<div class="g_btn btn bigbtn" @click="submitOrder">{{$t('common.submit')}}</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<!-- 手动下单 -->
									<div class="order_action_handle" v-if="tabIndex == 1">
										<div class="hd" v-if="web_type==1">
											<div class="left">
												<!-- <span class="icon">
													<img :src="require('@/assets/images/'+selectTool+'.png')" />
												</span> -->
												{{$t('stocks.mainOrder')}}
											</div>
											<div class="right">
												<div class="help" @click="handleHelp2">
													<i class="iconfont icon-tips"></i>
													{{$t('common.description')}}
												</div>
											</div>
										</div>
										<div class="action_form">
											<div class="title">{{$t('common.pirce')}}</div>
											<div class="inp_box">
												<el-input-number v-model="form.price" :step="0.01" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp biginp" />
											</div>
											<div class="title">{{$t('common.num')}}</div>
											<div class="inp_box">
												<el-input-number v-model="form.number" :step="0.01" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp biginp" />
											</div>
											<div class="balanceData">
												<template v-if="web!='opensea'">
													<!-- 主钱包 -->
													<div class="bigtitle">{{$t('stocks.mainAssets')}}</div>	
													<div>
														<div>
															<div class="title">{{yenUnit1.toUpperCase()}} </div>
															<div class="money">
																<span class="green">{{$t('common.available')}} {{balance1.balance?Math.floor(balance1.balance * 1000000) / 1000000:balance1.balance}}</span>
																<span class="red">{{$t('common.frozen')}} {{balance1.freeze?Math.floor(balance1.freeze * 1000000) / 1000000:balance1.freeze}}</span>
															</div>
														</div>
														<div>
															<div class="title">{{yenUnit2.toUpperCase()}} </div>
															<div class="money">
																<span class="green">{{$t('common.available')}} {{balance2.balance?Math.floor(balance2.balance * 1000000) / 1000000:balance2.balance}}</span>
																<span class="red">{{$t('common.frozen')}} {{balance2.freeze?Math.floor(balance2.freeze * 1000000) / 1000000:balance2.freeze}}</span>
															</div>
														</div>
													</div>
													<!-- 子钱包 -->
													<template v-if="Object.keys(subBalance).length>0">
														<div class="bigtitle">{{$t('stocks.subAssets')}}</div>	
														<div>
															<div v-for="(item,key,index) in subBalance" :key="index">
																<div class="title">{{key}} </div>
																<div class="money">
																	<span class="green">{{$t('common.available')}}  {{item?Math.floor(item * 1000000) / 1000000:item}}</span>
																	<span class="red">{{$t('common.frozen')}}  0</span>
																</div>
															</div>
														</div>
													</template>
												</template>
												<template v-else>
													<!-- opensea -->
													<template v-if="Object.keys(balance1).length>0">
														<div class="bigtitle">{{$t('stocks.mainAssets')}}</div>	
														<div>
															<div v-for="(item,key,index) in balance1" :key="index">
																<div class="title">{{key}} </div>
																<div class="money">
																	<span class="green">{{$t('common.available')}}  {{item?Math.floor(item * 1000000) / 1000000:item}}</span>
																	<!-- {{balance1.freeze?Math.floor(balance1.freeze * 1000000) / 1000000:balance1.freeze}} -->
																	<span class="red">{{$t('common.frozen')}}  0</span>
																</div>
															</div>
														</div>
													</template>
													<template v-if="Object.keys(subBalance).length>0">
														<div class="bigtitle">{{$t('stocks.subAssets')}}</div>	
														<div>
															<div v-for="(item,key,index) in subBalance" :key="index">
																<div class="title">{{key}} </div>
																<div class="money">
																	<span class="green">{{$t('common.available')}}  {{item?Math.floor(item * 1000000) / 1000000:item}}</span>
																	<span class="red">{{$t('common.frozen')}}  0</span>
																</div>
															</div>
														</div>
													</template>

													<template v-if="Object.keys(balance2).length>0">
														<div class="bigtitle">{{$t('stocks.mainAssetsNFT')}}</div>	
														<div>
															<div v-for="(item,key,index) in balance2" :key="index">
																<div class="title">{{key}} </div>
																<div class="money">
																	<span class="green">{{$t('common.available')}}  {{item?Math.floor(item * 1000000) / 1000000:item}}</span>
																	<!-- {{balance1.freeze?Math.floor(balance1.freeze * 1000000) / 1000000:balance1.freeze}} -->
																	<span class="red">{{$t('common.frozen')}}  0</span>
																</div>
															</div>
														</div>
													</template>
													<template v-if="web=='opensea'&&Object.keys(subBalance2).length>0">
														<div class="bigtitle">{{$t('stocks.subAssetsNFT')}}</div>	
														<div>
															<div v-for="(item,key,index) in subBalance2" :key="index">
																<div class="title">{{key}} </div>
																<div class="money">
																	<span class="green">{{$t('common.available')}}  {{item?Math.floor(item * 1000000) / 1000000:item}}</span>
																	<span class="red">{{$t('common.frozen')}}  0</span>
																</div>
															</div>
														</div>
													</template>
												</template>
											</div>
											<div class="bottom">
												<div class="g_btn btn" @click="handleOrder(1)">{{$t('common.purchase')}}</div>
												<div class="g_btn btn red" @click="handleOrder(0)">{{$t('common.sellout')}}</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<!-- 小屏 盘口 -->
						<div class="row_right content_right">
							<div class="order_info">
								<div class="head">
									<div class="left"><i class="iconfont icon-fullscreen"></i>{{$t('stocks.common')}}</div>
								</div>
								<div class="list">
									<div class="hd">
										<div class="price">{{$t('common.pirce')}}</div>
										<div class="number">{{$t('common.num')}}</div>
									</div>
									<div class="bd">
										<div class="left">
											<div @click="selectData(item)" class="item" v-for="(item,index) in asksList" :key="index">
												<div class="price">{{item.price}}</div>
												<div class="number">{{item.amount}}</div>
											</div>
											<div id="sell_end"></div>
										</div>
										<div class="line"></div>
										<div class="right">
											<div @click="selectData(item)" class="item" v-for="(item,index) in bidsList" :key="index">
												<div class="price">{{item.price}}</div>
												<div class="number">{{item.amount}}</div>
											</div>
										</div>
									</div>
								</div>
								
								<div class="head">
									<div class="right disabled" @click="handleFull" v-if="web_type==0||web_type==2"><i class="iconfont2 icon-quanping"></i>{{$t('stocks.advanced')}}</div>
								</div>
							</div>
						</div>
					</div>
					<!-- 订单/机器人管理 -->
					<div class="row_bottom">
						<div class="order_manage">
							<div class="head">
								<div class="left">
									<!-- opensea -->
									<div v-if="web_type==0||web_type==2" :class="{active:tab==0}" @click="tab=0">{{$t('stocks.orderManagement')}}</div>
									<div :class="{active:tab==1}" @click="switchTab">{{$t('stocks.robotMange')}}</div>
								</div>
								<div v-if="web_type==1" class="right" @click="jumpRecord">
									<i class="iconfont2 icon-jilu"></i>
									<span>{{$t('common.transactionRecord')}}</span>
								</div>
							</div>
							<!-- 订单管理 -->
							<div class="list" v-show="tab==0">
								<div class="hd">
									<div class="price">{{$t('common.pirce')}}</div>
									<div class="number">{{$t('common.num')}}</div>
									<div class="sale">{{$t('common.closed')}}</div>
									<div class="direction">{{$t('common.direction')}}</div>
									<div class="time">{{$t('common.time')}}</div>
									<div class="action">{{$t('common.cancelOrder')}}</div>
								</div>
								<div class="bd">
									<template v-if="orderList.length">
										<div v-infinite-scroll="loadOrders" 
										:infinite-scroll-delay="400"
										:infinite-scroll-disabled="load_disabled">
											<ul>
												<li class="item" 
												v-for="(item,index) in orderList" :key="index">
													<div class="price">{{item.price}}</div>
													<div class="number">{{item.amount}}</div>
													<div class="sale">{{item.deal_amount}}</div>
													<div class="direction">
														<span v-if="item.side=='buy'" class="green">{{$t('common.purchase')}}</span>
														<span v-else class="red">{{$t('common.sellout')}}</span>
													</div>
													<div class="time">{{item.time}}</div>
													<div class="action"><div class="btn" @click="handleCancel(item)"><span>{{$t('common.cancelOrder')}}</span></div></div>
												</li>
											</ul>
										</div>
										<p v-if="loading">{{$t('common.loding')}}...</p>
										<p v-if="load_disabled">{{$t('common.noMore')}}~</p>
									</template>
									<el-empty v-else :image="emptyImg" :image-size="120" :description="$t('common.noData')" />
								</div>
							</div>
							<!-- 机器人管理 -->
							<div class="robot_list" v-show="tab==1">
								<div class="hd" :class="{noData:!robotList.length}">
									<router-link v-if="robotList.length" tag="div" class="g_btn btn" :to="{path:'/addrobot',query:{isDis:1}}">{{$t('robot.addrobot')}}</router-link>
									
									<div class="switch">
										<el-checkbox v-model="isRun" true-label="1" false-label="0" @change="getRobotList">{{$t('robot.showRunning')}}</el-checkbox>
									</div>
								</div>
								<div class="bd">
									<template v-if="robotList.length">
										<div class="itemList">
											<div class="item" v-for="(item,index) in robotList" :key="index" :class="{active: activeIndex == index}" @click="activeIndex = index">
												<div class="caption">{{item.typeIndex?$t('robot.list['+item.typeIndex+'].name'):''}}<i class="iconfont icon-tips" @click="openTip(item.typeIndex)"></i></div>
												<div class="bd">

													<div class="li">
														<div class="title">{{$t('robot.parameter')}}</div>
														<div class="text">{{paramFormat(item)}}</div>
													</div>
													<div class="li">
														<div class="title">{{$t('robot.current')}}</div>
														<div class="text">
															<span v-if="item.state==1" class="green">{{$t('robot.running')}}</span>
															<span v-else class="red">{{$t('robot.stopped')}}</span>
														</div>
													</div>
													<div class="li">
														<div class="title">{{$t('common.operation')}}</div>
														<div class="text">
															<span class="btn green" @click="handleEdit(item,index)">{{$t('common.edit')}}</span>
															<span v-if="item.state==1" class="btn red" @click="handleStop(item,index)">{{$t('common.stop')}}</span>
															<span v-else class="btn green" @click="handleStart(item,index)">{{$t('common.run')}}</span>
															<span class="btn red" @click="handleDelete(item,index)">{{$t('common.del')}}</span>
														</div>
													</div>
												</div>
											</div>
										</div>
									</template>
									<el-empty v-else :image="emptyImg2" :image-size="90" :description="isRun==0?$t('robot.robotEmpty'):$t('robot.robotEmpty2')">
										<router-link tag="div" class="g_btn btn" :to="{path:'/addrobot',query:{isDis:1}}">{{$t('robot.addrobot')}}</router-link>
									</el-empty>
								</div>
							</div>
						</div>
					</div>
				</div>
				<!-- 右侧-普通盘口 -->
				<div class="content_right" v-if="device!='mobile'">
					<div class="order_info">
						<div class="head">
							<div class="left"><i class="iconfont icon-fullscreen"></i>{{$t('stocks.common')}}</div>
							<div class="right disabled" @click="handleFull" v-if="web_type==0||web_type==2"><i class="iconfont2 icon-quanping"></i>{{$t('stocks.advanced')}}</div>
						</div>
						<div class="list">
							<div class="hd">
								<div class="price">{{$t('common.pirce')}}</div>
								<div class="number">{{$t('common.num')}}</div>
							</div>
							<div class="bd">
								<div class="left">
									<div @click="selectData(item)" class="item" v-for="(item,index) in asksList" :key="index">
										<div class="price">{{item.price}}</div>
										<div class="number">{{item.amount}}</div>
									</div>
									<div id="sell_end"></div>
								</div>
								<div class="line"></div>
								<div class="right">
									<div @click="selectData(item)" class="item" v-for="(item,index) in bidsList" :key="index">
										<div class="price">{{item.price}}</div>
										<div class="number">{{item.amount}}</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</template>
		<!-- 移动端判定-普通盘口 -->
		<template v-else-if="isMobile==1">
			<div class="head_phone" @click="openPopCollect">
				<div class="exchange">
					<span>{{exchange}}</span>
					<span v-if="web!='opensea'">{{yenUnit1.toUpperCase()+'/'+yenUnit2.toUpperCase()}}</span>
					<span v-else>{{yenUnit1.toUpperCase()}}</span>
				</div>
				<div class="g_btn btn">
					<i class="iconfont2 icon-right_arrow"></i>
				</div>
			</div>
			<div class="content_phone">
				<div class="f1">
					<el-collapse value="1">
						<el-collapse-item :title="$t('stocks.tradingView')" name="1">
						<div class="k_line_box" v-if="web!='opensea'">
							<KLineWidget
								:web="web"
								:pair="symbol"
								ref="kLineWidget"
							></KLineWidget>
						</div>
						<!-- opensea -->
						<div class="opensea" v-else>
							<div class="img" v-if="!!nftInfo.collection_image">
								<img :src="nftInfo.collection_image" alt="">
							</div>
							<div class="img notImage" v-else>
								<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 532 532" fill="none">
									<path d="M292 26.5581L460.363 123.762C476.452 133.051 486.363 150.218 486.363 168.796V363.204C486.363 381.782 476.452 398.949 460.363 408.238L292 505.442C275.911 514.731 256.089 514.731 240 505.442L71.6372 408.238C55.5484 398.949 45.6372 381.782 45.6372 363.204V168.796C45.6372 150.218 55.5484 133.051 71.6372 123.762L240 26.5581C256.089 17.2692 275.911 17.2692 292 26.5581Z" stroke="#96A0AB" stroke-width="20"/>
									<path d="M102.592 372C96.1973 372 93 369.802 93 365.406V167.594C93 163.198 96.1973 161 102.592 161H108.65C111.174 161 113.194 161.5 114.708 162.499C116.223 163.298 117.485 164.796 118.494 166.994L177.056 300.967C179.412 306.162 181.599 312.456 183.619 319.849L184.881 319.25C183.534 312.257 182.861 305.663 182.861 299.469V167.594C182.861 163.198 186.059 161 192.453 161H195.482C201.877 161 205.074 163.198 205.074 167.594V365.406C205.074 369.802 201.877 372 195.482 372H190.182C185.47 372 182.441 370.501 181.094 367.504L121.019 229.036C118.663 223.84 116.475 217.546 114.456 210.153L113.194 210.753C114.54 217.746 115.213 224.34 115.213 230.534V365.406C115.213 369.802 112.016 372 105.621 372H102.592Z" fill="#96A0AB"/>
									<path d="M252.742 372C246.348 372 243.15 369.802 243.15 365.406V170.591C243.15 167.394 243.655 165.096 244.665 163.697C245.843 162.299 247.61 161.599 249.966 161.599H315.595C318.792 161.599 320.391 164.597 320.391 170.591V175.386C320.391 181.381 318.792 184.378 315.595 184.378H265.868V258.707H311.303C314.501 258.707 316.099 261.705 316.099 267.699V272.494C316.099 278.489 314.501 281.486 311.303 281.486H265.868V365.406C265.868 369.802 262.671 372 256.276 372H252.742Z" fill="#96A0AB"/>
									<path d="M385.982 372C379.588 372 376.39 369.802 376.39 365.406V184.378H340.042C336.845 184.378 335.246 181.381 335.246 175.386V170.591C335.246 164.597 336.845 161.599 340.042 161.599H434.952C436.803 161.599 438.065 162.299 438.738 163.697C439.579 165.096 440 167.394 440 170.591V175.386C440 178.583 439.579 180.881 438.738 182.28C438.065 183.679 436.803 184.378 434.952 184.378H399.108V365.406C399.108 369.802 395.911 372 389.516 372H385.982Z" fill="#96A0AB"/>
								</svg>
							</div>
							<div class="detail_box">
								<div class="title">{{nftInfo.collection_name}}</div>
								<div>
									<div class="label">{{$t('stocks.contractAddress')}}: </div>
									<el-tooltip class="item" effect="dark" :content="$t('common.copy')" placement="top" :enterable="false">
										<div class="value value2 copyBtn" @click="handleCopy">
												{{nftInfo.contract_address}}
										</div>
									</el-tooltip>
								</div>
								<div class="sub" :class="{detail:!more}">{{nftInfo.description}}</div>
								<div class="btn" v-if="more==false" @click="more=true">{{$t('stocks.more')}}</div>
								<div class="btn" v-else @click="more=false">{{$t('stocks.less')}}</div>
								<div>
									<div class="label">{{$t('stocks.date')}}: </div>
									<div class="value">{{nftInfo.created_date}}</div>
								</div>
								<div>
									<div class="label">{{$t('stocks.items')}}: </div>
									<div class="value">{{nftInfo.items}}</div>
								</div>
								<div>
									<div class="label">{{$t('stocks.owners')}}: </div>
									<div class="value">{{nftInfo.owners}}</div>
								</div>
								<div>
									<div class="label">{{$t('stocks.totalVolume')}}: </div>
									<div class="value">
										<div class="currency_icon" v-if="!!nftInfo.currency_image">
											<img :src="nftInfo.currency_image" alt="">
										</div>
										{{nftInfo.total_volume}}
									</div>
								</div>
								<div>
									<div class="label">{{$t('stocks.floor')}}: 
									</div>
									<div class="value">
										<div class="currency_icon" v-if="!!nftInfo.currency_image">
											<img :src="nftInfo.currency_image" alt="">
										</div>
										{{nftInfo.floor_price}}
									</div>
								</div>
								<div>
									<div class="label">{{$t('stocks.average')}}: </div>
									<div class="value">
										<div class="currency_icon" v-if="!!nftInfo.currency_image">
											<img :src="nftInfo.currency_image" alt="">
										</div>
										{{nftInfo.average_price}}
									</div>
								</div>
								<div>
									<div class="label">{{$t('stocks.currency')}}: </div>
									<div class="value">{{nftInfo.currency}}</div>
								</div>
							</div>
						</div>

						</el-collapse-item>
					</el-collapse>
					<div class="tool_open">
						<div class="tool_box">
							<div class="tabTit">
								<ul>
									<li :class="{active: tabIndex == 1}" @click="tabIndex = 1">{{$t('stocks.order')}}</li>
									<li :class="{active: tabIndex == 0}" @click="tabIndex = 0">{{(web_type==0?'CEX ':web_type==1?'DEX ':'NFT ')+$t('stocks.tools')}}</li>
								</ul>
							</div>
							<!-- 量化工具 -->
							<div class="order_action_tool" v-if="tabIndex == 0">
								<div class="form">
									<div class="hd">
										<div class="left" @click="openToolSelect">
											{{$t('tools.'+selectTool+'.name')}}
											<i class="iconfont2 icon-right_arrow"></i>
										</div>
										<div class="right">
											<div class="help" @click="handleHelp"><i class="iconfont icon-tips"></i></div>
										</div>
									</div>
									<div class="bd">
										<div class="action_form">
											<!-- 批量下买单/卖单 -->
											<div v-if="web!='opensea'&&(selectTool=='check'||selectTool=='sale')">
												<div class="title">{{selectTool=='check'?$t('stocks.check'):$t('stocks.sale')}}</div>
												<div class="inp_box">
													<el-input-number v-model="toolform.lowPrice" :step="0.01" controls-position="right" :placeholder="$t('common.minValue')" :min="0" class="inp" />
													~ <el-input-number v-model="toolform.highPrice" :step="0.01" controls-position="right" :placeholder="$t('common.maxValue')" :min="toolform.lowPrice" class="inp" />
												</div>
												<div class="title">{{$t('stocks.quantity')}}</div>
												<div class="inp_box">
													<el-input-number v-model="toolform.lowNum" :step="0.01" controls-position="right" :placeholder="$t('common.minValue')" :min="0" class="inp" />
													~ <el-input-number v-model="toolform.highNum" :step="0.01" controls-position="right" :placeholder="$t('common.maxValue')" :min="toolform.lowNum" class="inp" />
												</div>
												<div class="title">{{$t('stocks.total')}}</div>
												<div class="inp_box">
													<el-input-number v-model="toolform.totalNum" :step="1" controls-position="right" :placeholder="$t('common.max')+' 500'" :min="0" class="inp biginp" step-strictly />
												</div>
												<div class="title">{{$t('stocks.decimal')}}</div>
												<div class="inp_box">
													<el-input-number v-model="toolform.decimal" :step="1" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp biginp" step-strictly />
												</div>
											</div>
											<!-- 批量撤单 -->
											<div v-else-if="selectTool=='revoke'">
												<div class="title">{{$t('stocks.priceRange')}}</div>
												<div class="inp_box">
													<el-input-number v-model="toolform.lowPrice" :step="0.01" controls-position="right" :placeholder="$t('common.minValue')" :min="0" class="inp" />
													~ <el-input-number v-model="toolform.highPrice" :step="0.01" controls-position="right" :placeholder="$t('common.maxValue')" :min="toolform.lowPrice" class="inp" />
												</div>
												<div class="title">{{$t('stocks.quantityRange')}}</div>
												<div class="inp_box">
													<el-input-number v-model="toolform.lowNum" :step="0.01" controls-position="right" :placeholder="$t('common.minValue')" :min="0" class="inp" />
													~ <el-input-number v-model="toolform.highNum" :step="0.01" controls-position="right" :placeholder="$t('common.maxValue')" :min="toolform.lowNum" class="inp" />
												</div>
											</div>

											<div v-else-if="selectTool=='calculate'">
												<div class="title">{{$t('stocks.target')}}</div>
												<div class="inp_box">
													<el-input-number v-model="toolform.target" :step="0.01" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp biginp" />
													<span class="company green">{{yenUnit2}}</span>
												</div>
											</div>
											<div v-else-if="selectTool=='transaction'">

												<div class="title">{{$t('stocks.strategy')}}</div>
												<div class="radio_box">
													<el-radio v-model="toolform.strategy" label="1">{{$t('stocks.strategyDefault')}}</el-radio>
													<el-radio v-model="toolform.strategy" label="2">{{$t('stocks.strategyCondition')}}</el-radio>
												</div>

												<div class="title" v-if="toolform.strategy==2">{{$t('stocks.walletsNumber')}}</div>
												<div class="inp_box" v-if="toolform.strategy==2">
													<el-input-number v-model="toolform.walletsNumber" :step="1" controls-position="right" :placeholder="$t('common.value')" :min="1" class="inp biginp" />
													<span class="company"></span>
												</div>

												<div class="title">{{$t('stocks.volume')}}</div>
												<div class="inp_box">
													<el-input-number v-model="toolform.volume" :step="0.01" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp biginp" />
													<span class="company" :class="toolform.transactionType==1?'green':'red'">{{!toolform.transactionType?'':toolform.transactionType==1?yenUnit2:yenUnit1}}</span>
												</div>
												
												<div class="title" v-if="toolform.strategy==2">{{$t('stocks.floatingRange')}}</div>
												<div class="inp_box" v-if="toolform.strategy==2" id="float">
													<el-input-number @input.native="floatChange" v-model="toolform.floatingRange" :step="0.01" controls-position="right" :placeholder="$t('common.value')" :min="0" :max="80" class="inp biginp" />
													<span class="company util">%</span>
												</div>
                    							<div class="tip" v-if="is0">{{$t('stocks.floatTip0')}}</div>

												<div class="title">{{$t('stocks.transactionType')}}</div>
												<div class="radio_box">
													<el-radio v-model="toolform.transactionType" label="1">{{$t('stocks.buy')}}</el-radio>
													<el-radio v-model="toolform.transactionType" label="0">{{$t('stocks.sell')}}</el-radio>
												</div>
											</div>
											<div v-else-if="selectTool=='empower'">
												<div class="title">{{$t('stocks.token')}}</div>
												<div class="inp_box">
													<input v-model="toolform.token"  controls-position="right" :placeholder="$t('stocks.token')" class="inp biginp chain" />
												</div>
												<!-- <div class="title">{{$t('stocks.walletType')}}</div>
												<div class="inp_box">
													<el-select v-model="toolform.walletType" :placeholder="$t('common.select')">
														<el-option
														:label="$t('stocks.mainWallet')"
														value="1">
														</el-option>
														<el-option
														:label="$t('stocks.subWallet')"
														value="0">
														</el-option>
													</el-select>
												</div> -->
											</div>
											<!-- nft 批量下买单/卖单 -->
											<!-- 交易数量、买单价格范围 -->
											<div v-else-if="web=='opensea'&&(selectTool=='check'||selectTool=='sale')">
												<div class="title">{{selectTool=='check'?$t('stocks.check'):$t('stocks.sale')}}</div>
												<div class="inp_box">
													<el-input-number v-model="toolform.lowPrice" :step="0.01" controls-position="right" :placeholder="$t('common.minValue')" :min="0" class="inp" />
													~ <el-input-number v-model="toolform.highPrice" :step="0.01" controls-position="right" :placeholder="$t('common.maxValue')" :min="toolform.lowPrice" class="inp" />
												</div>
												<div class="title">{{$t('stocks.quantity')}}</div>
												<div class="inp_box">
													<el-input-number v-model="toolform.lowNum" :step="0.01" controls-position="right" :placeholder="$t('common.minValue')" :min="0" class="inp" />
													~ <el-input-number v-model="toolform.highNum" :step="0.01" controls-position="right" :placeholder="$t('common.maxValue')" :min="toolform.lowNum" class="inp" />
												</div>
												<!-- <div class="title">{{$t('stocks.total')}}</div>
												<div class="inp_box">
													<el-input-number v-model="toolform.totalNum" :step="1" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp biginp" step-strictly />
												</div>
												<div class="title">{{$t('stocks.decimal')}}</div>
												<div class="inp_box">
													<el-input-number v-model="toolform.decimal" :step="1" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp biginp" step-strictly />
												</div> -->
											</div>
											<!-- 钱包数据 -->
											<div class="balanceData">	
												<template v-if="web!='opensea'">
													<!-- 主钱包 -->
													<div class="bigtitle">{{$t('stocks.mainAssets')}}</div>	
													<div>
														<div>
															<div class="title">{{yenUnit1.toUpperCase()}} </div>
															<div class="money">
																<span class="green">{{$t('common.available')}} {{balance1.balance?Math.floor(balance1.balance * 1000000) / 1000000:balance1.balance}}</span>
																<span class="red">{{$t('common.frozen')}} {{balance1.freeze?Math.floor(balance1.freeze * 1000000) / 1000000:balance1.freeze}}</span>
															</div>
														</div>
														<div>
															<div class="title">{{yenUnit2.toUpperCase()}} </div>
															<div class="money">
																<span class="green">{{$t('common.available')}} {{balance2.balance?Math.floor(balance2.balance * 1000000) / 1000000:balance2.balance}}</span>
																<span class="red">{{$t('common.frozen')}} {{balance2.freeze?Math.floor(balance2.freeze * 1000000) / 1000000:balance2.freeze}}</span>
															</div>
														</div>
													</div>
													<!-- 子钱包 -->
													<template v-if="Object.keys(subBalance).length>0">
														<div class="bigtitle">{{$t('stocks.subAssets')}}</div>	
														<div>
															<div v-for="(item,key,index) in subBalance" :key="index">
																<div class="title">{{key}} </div>
																<div class="money">
																	<span class="green">{{$t('common.available')}}  {{item?Math.floor(item * 1000000) / 1000000:item}}</span>
																	<span class="red">{{$t('common.frozen')}}  0</span>
																</div>
															</div>
														</div>
													</template>
												</template>
												<template v-else>
													<!-- opensea -->
													<template v-if="Object.keys(balance1).length>0">
														<div class="bigtitle">{{$t('stocks.mainAssets')}}</div>	
														<div>
															<div v-for="(item,key,index) in balance1" :key="index">
																<div class="title">{{key}} </div>
																<div class="money">
																	<span class="green">{{$t('common.available')}}  {{item?Math.floor(item * 1000000) / 1000000:item}}</span>
																	<!-- {{balance1.freeze?Math.floor(balance1.freeze * 1000000) / 1000000:balance1.freeze}} -->
																	<span class="red">{{$t('common.frozen')}}  0</span>
																</div>
															</div>
														</div>
													</template>
													<template v-if="Object.keys(subBalance).length>0">
														<div class="bigtitle">{{$t('stocks.subAssets')}}</div>	
														<div>
															<div v-for="(item,key,index) in subBalance" :key="index">
																<div class="title">{{key}} </div>
																<div class="money">
																	<span class="green">{{$t('common.available')}}  {{item?Math.floor(item * 1000000) / 1000000:item}}</span>
																	<span class="red">{{$t('common.frozen')}}  0</span>
																</div>
															</div>
														</div>
													</template>

													<template v-if="Object.keys(balance2).length>0">
														<div class="bigtitle">{{$t('stocks.mainAssetsNFT')}}</div>	
														<div>
															<div v-for="(item,key,index) in balance2" :key="index">
																<div class="title">{{key}} </div>
																<div class="money">
																	<span class="green">{{$t('common.available')}}  {{item?Math.floor(item * 1000000) / 1000000:item}}</span>
																	<!-- {{balance1.freeze?Math.floor(balance1.freeze * 1000000) / 1000000:balance1.freeze}} -->
																	<span class="red">{{$t('common.frozen')}}  0</span>
																</div>
															</div>
														</div>
													</template>
													<template v-if="web=='opensea'&&Object.keys(subBalance2).length>0">
														<div class="bigtitle">{{$t('stocks.subAssetsNFT')}}</div>	
														<div>
															<div v-for="(item,key,index) in subBalance2" :key="index">
																<div class="title">{{key}} </div>
																<div class="money">
																	<span class="green">{{$t('common.available')}}  {{item?Math.floor(item * 1000000) / 1000000:item}}</span>
																	<span class="red">{{$t('common.frozen')}}  0</span>
																</div>
															</div>
														</div>
													</template>
												</template>
											</div>
											<div class="bottom">
												<div class="g_btn btn bigbtn" @click="submitOrder">{{$t('common.submit')}}</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<!-- 手动下单 -->
							<div class="order_action_handle" v-if="tabIndex == 1">
								<div class="hd" v-if="web_type==1">
									<div class="left">
										<!-- <span class="icon">
											<img :src="require('@/assets/images/'+selectTool+'.png')" />
										</span> -->
										{{$t('stocks.mainOrder')}}
									</div>
									<div class="right">
										<div class="help" @click="handleHelp2">
											<i class="iconfont icon-tips"></i>
										</div>
									</div>
								</div>
								<div class="action_form">
									<div class="title">{{$t('common.pirce')}}</div>
									<div class="inp_box">
										<el-input-number v-model="form.price" :step="0.01" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp biginp" />
									</div>
									<div class="title">{{$t('common.num')}}</div>
									<div class="inp_box">
										<el-input-number v-model="form.number" :step="0.01" controls-position="right" :placeholder="$t('common.value')" :min="0" class="inp biginp" />
									</div>
									<div class="balanceData">
										<template v-if="web!='opensea'">
											<!-- 主钱包 -->
											<div class="bigtitle">{{$t('stocks.mainAssets')}}</div>	
											<div>
												<div>
													<div class="title">{{yenUnit1.toUpperCase()}} </div>
													<div class="money">
														<span class="green">{{$t('common.available')}} {{balance1.balance?Math.floor(balance1.balance * 1000000) / 1000000:balance1.balance}}</span>
														<span class="red">{{$t('common.frozen')}} {{balance1.freeze?Math.floor(balance1.freeze * 1000000) / 1000000:balance1.freeze}}</span>
													</div>
												</div>
												<div>
													<div class="title">{{yenUnit2.toUpperCase()}} </div>
													<div class="money">
														<span class="green">{{$t('common.available')}} {{balance2.balance?Math.floor(balance2.balance * 1000000) / 1000000:balance2.balance}}</span>
														<span class="red">{{$t('common.frozen')}} {{balance2.freeze?Math.floor(balance2.freeze * 1000000) / 1000000:balance2.freeze}}</span>
													</div>
												</div>
											</div>
											<!-- 子钱包 -->
											<template v-if="Object.keys(subBalance).length>0">
												<div class="bigtitle">{{$t('stocks.subAssets')}}</div>	
												<div>
													<div v-for="(item,key,index) in subBalance" :key="index">
														<div class="title">{{key}} </div>
														<div class="money">
															<span class="green">{{$t('common.available')}}  {{item?Math.floor(item * 1000000) / 1000000:item}}</span>
															<span class="red">{{$t('common.frozen')}}  0</span>
														</div>
													</div>
												</div>
											</template>
										</template>
										<template v-else>
											<!-- opensea -->
											<template v-if="Object.keys(balance1).length>0">
												<div class="bigtitle">{{$t('stocks.mainAssets')}}</div>	
												<div>
													<div v-for="(item,key,index) in balance1" :key="index">
														<div class="title">{{key}} </div>
														<div class="money">
															<span class="green">{{$t('common.available')}}  {{item?Math.floor(item * 1000000) / 1000000:item}}</span>
															<!-- {{balance1.freeze?Math.floor(balance1.freeze * 1000000) / 1000000:balance1.freeze}} -->
															<span class="red">{{$t('common.frozen')}}  0</span>
														</div>
													</div>
												</div>
											</template>
											<template v-if="Object.keys(subBalance).length>0">
												<div class="bigtitle">{{$t('stocks.subAssets')}}</div>	
												<div>
													<div v-for="(item,key,index) in subBalance" :key="index">
														<div class="title">{{key}} </div>
														<div class="money">
															<span class="green">{{$t('common.available')}}  {{item?Math.floor(item * 1000000) / 1000000:item}}</span>
															<span class="red">{{$t('common.frozen')}}  0</span>
														</div>
													</div>
												</div>
											</template>

											<template v-if="Object.keys(balance2).length>0">
												<div class="bigtitle">{{$t('stocks.mainAssetsNFT')}}</div>	
												<div>
													<div v-for="(item,key,index) in balance2" :key="index">
														<div class="title">{{key}} </div>
														<div class="money">
															<span class="green">{{$t('common.available')}}  {{item?Math.floor(item * 1000000) / 1000000:item}}</span>
															<!-- {{balance1.freeze?Math.floor(balance1.freeze * 1000000) / 1000000:balance1.freeze}} -->
															<span class="red">{{$t('common.frozen')}}  0</span>
														</div>
													</div>
												</div>
											</template>
											<template v-if="web=='opensea'&&Object.keys(subBalance2).length>0">
												<div class="bigtitle">{{$t('stocks.subAssetsNFT')}}</div>	
												<div>
													<div v-for="(item,key,index) in subBalance2" :key="index">
														<div class="title">{{key}} </div>
														<div class="money">
															<span class="green">{{$t('common.available')}}  {{item?Math.floor(item * 1000000) / 1000000:item}}</span>
															<span class="red">{{$t('common.frozen')}}  0</span>
														</div>
													</div>
												</div>
											</template>
										</template>
									</div>
									<div class="bottom">
										<div class="g_btn btn" @click="handleOrder(1)">{{$t('common.purchase')}}</div>
										<div class="g_btn btn red" @click="handleOrder(0)">{{$t('common.sellout')}}</div>
									</div>
								</div>
							</div>
						</div>
						<div class="open_box">
							<div class="order_info">
								<!-- 普通盘口 -->
								<div class="head head_title">
									<div class="left">{{$t('stocks.common')}}</div>
								</div>
								<div class="list">
									<div class="hd">
										<div class="price">{{$t('common.pirce')}}</div>
										<div class="number">{{$t('common.num')}}</div>
									</div>
									<div class="bd">
										<div class="left">
											<div @click="selectData(item)" class="item" v-for="(item,index) in asksList" :key="index">
												<div class="price">{{item.price}}</div>
												<div class="number">{{numFormat(item.amount)}}</div>
											</div>
											<div id="sell_end"></div>
										</div>
										<div class="line"></div>
										<div class="right">
											<div @click="selectData(item)" class="item" v-for="(item,index) in bidsList" :key="index">
												<div class="price">{{item.price}}</div>
												<div class="number">{{numFormat(item.amount)}}</div>
											</div>
										</div>
									</div>
								</div>
								<!-- 高级盘口 -->
								<div class="head">
									<div class="right disabled" @click="handleFull" v-if="web_type==0||web_type==2">
										<span>{{$t('stocks.advanced')}}</span>
										<i class="iconfont2 icon-right_arrow"></i>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				
				<!-- 订单/机器人管理 -->
				<div class="row_bottom">
					<div class="order_manage">
						<div class="head">
							<div class="left">
								<div v-if="web_type==0||web_type==2" :class="{active:tab==0}" @click="tab=0">{{$t('stocks.orderManagement')}}</div>
								<div :class="{active:tab==1}" @click="switchTab">{{$t('stocks.robotMange')}}</div>
							</div>
							<div v-if="web_type==1" class="right" @click="jumpRecord">
								<i class="iconfont2 icon-jilu"></i>
								<span>{{$t('common.transactionRecord')}}</span>
							</div>
						</div>
						<!-- 订单管理 -->
						<div class="list" v-show="tab==0">
							<div class="hd">
								<div class="price">{{$t('common.pirce')}}</div>
								<div class="number">{{$t('common.num')}}</div>
								<div class="sale">{{$t('common.closed')}}</div>
								<div class="direction">{{$t('common.direction')}}</div>
								<div class="time">{{$t('common.time')}}</div>
								<div class="action">{{$t('common.cancelOrder')}}</div>
							</div>
							<div class="bd">
								<template v-if="orderList.length">
									<div v-infinite-scroll="loadOrders" 
									:infinite-scroll-delay="400"
									:infinite-scroll-disabled="load_disabled">
										<ul>
											<li class="item" 
											v-for="(item,index) in orderList" :key="index">
												<div class="price">{{item.price}}</div>
												<div class="number">{{item.amount}}</div>
												<div class="sale">{{item.deal_amount}}</div>
												<div class="direction">
													<span v-if="item.side=='buy'" class="green">{{$t('common.purchase')}}</span>
													<span v-else class="red">{{$t('common.sellout')}}</span>
												</div>
												<div class="time">{{item.time}}</div>
												<div class="action"><div class="btn" @click="handleCancel(item)"><span>{{$t('common.cancelOrder')}}</span></div></div>
											</li>
										</ul>
									</div>
									<p v-if="loading">{{$t('common.loding')}}...</p>
									<p v-if="load_disabled">{{$t('common.noMore')}}~</p>
								</template>
								<el-empty v-else :image="emptyImg" :image-size="120" :description="$t('common.noData')" />
							</div>
						</div>
						<!-- 机器人管理 -->
						<div class="robot_list" v-show="tab==1">
							<div class="hd" :class="{noData:!robotList.length}">
								<router-link v-if="robotList.length" tag="div" class="g_btn btn" :to="{path:'/addrobot',query:{isDis:1}}">{{$t('robot.addrobot')}}</router-link>
								
								<div class="switch">
									<el-checkbox v-model="isRun" true-label="1" false-label="0" @change="getRobotList">{{$t('robot.showRunning')}}</el-checkbox>
								</div>
							</div>
							<div class="bd">
								<template v-if="robotList.length">
									<div class="itemList">
										<div class="item" v-for="(item,index) in robotList" :key="index" :class="{active: activeIndex == index}" @click="activeIndex = index">
											<div class="caption">{{item.typeIndex?$t('robot.list['+item.typeIndex+'].name'):''}}<i class="iconfont icon-tips" @click="openTip(item.typeIndex)"></i></div>
											<div class="bd">

												<div class="li">
													<div class="title">{{$t('robot.parameter')}}</div>
													<div class="text">{{paramFormat(item)}}</div>
												</div>
												<div class="li">
													<div class="title">{{$t('robot.current')}}</div>
													<div class="text">
														<span v-if="item.state==1" class="green">{{$t('robot.running')}}</span>
														<span v-else class="red">{{$t('robot.stopped')}}</span>
													</div>
												</div>
												<div class="li">
													<div class="title">{{$t('common.operation')}}</div>
													<div class="text">
														<span class="btn green" @click="handleEdit(item,index)">{{$t('common.edit')}}</span>
														<span v-if="item.state==1" class="btn red" @click="handleStop(item,index)">{{$t('common.stop')}}</span>
														<span v-else class="btn green" @click="handleStart(item,index)">{{$t('common.run')}}</span>
														<span class="btn red" @click="handleDelete(item,index)">{{$t('common.del')}}</span>
													</div>
												</div>
											</div>
										</div>
									</div>
								</template>
								<el-empty v-else :image="emptyImg2" :image-size="90" :description="isRun==0?$t('robot.robotEmpty'):$t('robot.robotEmpty2')">
									<router-link tag="div" class="g_btn btn" :to="{path:'/addrobot',query:{isDis:1}}">{{$t('robot.addrobot')}}</router-link>
								</el-empty>
							</div>
						</div>
					</div>
				</div>

			</div>
		</template>

		<!-- 交易所选择 -->
		<exchange-dialog
			:show.sync="popExchange"
			:web="web"
			@close="closeExchange"
			@select="handleSelect"
		/>
		<!-- 功能说明弹窗 -->
		<el-dialog class="w900" :title="$t('tools.'+selectTool+'.name')+$t('common.explain')" center :visible.sync="showHelp" :show-close="false">
			<div class="tips_form" v-html="$t('tools.'+selectTool+'.html')"></div>
		</el-dialog>
		<!-- 手动下单功能说明弹窗 -->
		<el-dialog class="w900" :title="$t('stocks.order')+$t('common.explain')" center :visible.sync="showHelp2" :show-close="false">
			<div class="tips_form" style="color: #9198AA;">
				{{$t('stocks.orderTip')}}
			</div>
		</el-dialog>
		<!-- 新手指引弹窗 -->
		<div class="mask" v-if="novice==1&&isMobile==0"></div>
		<!-- 引导步骤 -->
		<guidance :position2="position2" v-if="novice==1&&helpNum==2&&isMobile==0"></guidance>
		<guidance :position2="position2" v-else-if="novice==1&&helpNum==3&&isMobile==0"></guidance>
		<guidance :position2="position2" v-else-if="novice==1&&helpNum==4&&isMobile==0"></guidance>
		<!-- 选择盘口 常用盘口 -->
		<!--  @close="closeColl" -->
		<el-dialog class="collect" :title="$t('common.selectOpen')" center :visible.sync="popCollection" :show-close="false">
			<div class="title">{{$t('common.commonly')}}</div>
			<div class="company_box">
				<div class="tab_title">
					<div class="left">{{$t('common.exchange')}}</div>
					<div class="right">{{$t('common.pair')}}</div>
				</div>
				<div class="item" v-for="(item,index) in collectList" :key="index" @click="commonlyTc(item)">
					<div class="left">{{item.exchange_name}}</div>
					<div class="right">
						<div>{{item.currency}}</div>
						<div class="collect_btn" @click.stop="collectCancel(item.currency,item.web_id,item.web)">
							<img src="@/assets/images/collection_active.png" alt="">
						</div>
					</div>
				</div>
			</div>
			<div class="title">{{$t('stocks.manual')}}</div>
			<div class="manual_box">
				<div class="select" @click="popExchange = true">
					<input v-model="popexchange" type="text" :placeholder="$t('common.select')" readonly class="inp" />
					<i class="iconfont icon-down"></i>
				</div>
				<div class="pair" v-if="popweb!='opensea'">
					<input v-model="popyenUnit1" type="text" :placeholder="$t('common.currency')" class="inp" />
					<i class="split"></i>
					<input v-model="popyenUnit2" type="text" :placeholder="$t('common.currency')" class="inp" />
				</div>
				<div class="pair opensea" v-else>
					<input v-model="popyenUnit1" type="text" :placeholder="$t('common.opensea')" class="inp" />
				</div>
			</div>
			<div class="foot">
				<span class="g_btn btn" @click="popCollection=false">{{$t('common.back')}}</span>
				<span class="g_btn btn submit" @click="handleInConfirm">{{$t('common.confirm')}}</span>
			</div>
		</el-dialog>
		<!-- 编辑弹窗 -->
		<el-dialog :title="typeIndex?$t('common.editTitle')+$t('robot.list['+typeIndex+'].name'):''" :visible.sync="showEdit" :show-close="false" center :before-close="beforeClose">
			
			<robotForm
				ref="robotForm"
				:titleIndex="titleIndex"
				@handleCancel="handleEditCancel"
				@handleConfirm="handleConfirm(arguments)"
				@openPop="openPop"
				:yenUnit1="yenUnit1"
				:yenUnit2="yenUnit2"
				:isEdit="true"
				:state="selectItmeState"
			></robotForm>

		</el-dialog>
		<!-- 机器人说明弹窗 -->
		<el-dialog class="w900" :title="typeIndex?$t('robot.list['+typeIndex+'].name')+$t('common.explain'):''" center :visible.sync="showTips" :show-close="false" @close="activeIndex = null">
			<div class="tips_form" v-html="typeIndex?$t('robot.list['+typeIndex+'].html'):''"></div>
		</el-dialog>

		<!-- 机器人关闭倒计时 -->
		<template v-if="closingRobotList.length>0">
			<div class="notification" :class="{active:closingRobot[item]>0}" v-for="(item,index) in closingRobotList" :key="index" :style="{top:index==0?70+'px':70+index*90+'px'}">
				<div class="notification__group">
					<h2 class="notification__title"> {{$t('robot.list['+item+'].name')}} </h2>
					<div class="notification__content">
						<p>
							{{$t('tip.closing',{time:closingRobot[item]})}}
						</p>
					</div>
				</div>
			</div>
		</template>

		<!-- 分拨资金弹窗 -->
		<subTransfer 
		ref="subTransfer"
		:address_list="address_list"
		:id_list="id_list"
		:chain="chain"
		:token="token"
		:approve_token="approve_token"
		:web="transferWeb"
		:exchange="transferExchange"
		></subTransfer>

		<!-- 移动端判定相关弹窗 -->
		<!-- 量化工具选择 -->
        <van-action-sheet 
        v-model="tool_box_show" 
        :title="$t('stocks.toolSelect')"
        :closeable="false">
			<div class="toolList">
				<template v-if="web_type==0">
					<!-- cex量化工具 -->
					<div class="item" v-for="(item,index) in toolList" :key="index" @click="handleCreate2(item,type>=2)">
						<div class="left" :class="{notOpen:type<2}">
							<div class="title"><span class="icon"><img :src="require('@/assets/images/'+item+'.png')" /></span>{{$t('tools.'+item+'.name')}}</div>
							<div class="desc">{{$t('tools.'+item+'.sub')}}</div>
						</div>
						<div class="right">
							<div v-if="type>=2" class="btn">
								<!-- {{$t('common.into')}} -->
								<div v-if="selectTool==item">
									<i class="iconfont2 icon-yes"></i>
								</div>
							</div>
							<div v-else class="btn">{{$t('common.open')}}</div>
						</div>
					</div>
				</template>
				<template v-else-if="web_type==1">
					<!-- dex子钱包管理入口 -->
					<div class="item" @click="handleManage2(type>=4)">
						<div class="left" :class="{notOpen:type<4}">
							<div class="title"><span class="icon"><img :src="require('@/assets/images/wallet.png')" /></span>{{$t('tools.manage.name')}}</div>
							<div class="desc">{{$t('tools.manage.sub')}}</div>
						</div>
						<div class="right">
							<div v-if="type>=4" class="btn">{{$t('common.into')}}</div>
							<div v-else class="btn">{{$t('common.open')}}</div>
						</div>
					</div>
					<!-- dex量化工具 -->
					<div class="item" v-for="(item,index) in toolDexList" :key="'0' + index" @click="handleCreate2(item,type>=4)">
						<div class="left" :class="{notOpen:type<4}">
							<div class="title"><span class="icon"><img :src="require('@/assets/images/'+item+'.png')" /></span>{{$t('tools.'+item+'.name')}}</div>
							<div class="desc">{{$t('tools.'+item+'.sub')}}</div>
						</div>
						<div class="right">
							<div v-if="type>=4" class="btn">
								<!-- {{$t('common.into')}} -->
								<div v-if="selectTool==item">
									<i class="iconfont2 icon-yes"></i>
								</div>
							</div>
							<div v-else class="btn">{{$t('common.open')}}</div>
						</div>
					</div>
				</template>
				<template v-else-if="web_type==2">
					<!-- NFT钱包管理入口 -->
					<div class="item" @click="handleManage2(type>=4)">
						<div class="left" :class="{notOpen:type<4}">
							<div class="title"><span class="icon"><img :src="require('@/assets/images/wallet.png')" /></span>{{$t('tools.manageNFT.name')}}</div>
							<div class="desc">{{$t('tools.manageNFT.sub')}}</div>
						</div>
						<div class="right">
							<div v-if="type>=4" class="btn">{{$t('common.into')}}</div>
							<div v-else class="btn">{{$t('common.open')}}</div>
						</div>
					</div>
					<!-- nft量化工具 -->
					<div class="item" v-for="(item,index) in toolNFTList" :key="index" @click="handleCreate2(item,type>=2)">
						<div class="left" :class="{notOpen:type<4}">
							<div class="title"><span class="icon"><img :src="require('@/assets/images/'+item+'.png')" /></span>{{$t('tools.'+item+'.name')}}</div>
							<div class="desc">{{$t('tools.'+item+'.sub')}}</div>
						</div>
						<div class="right">
							<div v-if="type>=2" class="btn">
								<!-- {{$t('common.into')}} -->
								<div v-if="selectTool==item">
									<i class="iconfont2 icon-yes"></i>
								</div>
							</div>
							<div v-else class="btn">{{$t('common.open')}}</div>
						</div>
					</div>
				</template>
			</div>
        </van-action-sheet>
	</div>
</template>

<style scoped="scoped" lang="stylus">
	@import 'index.styl';
</style>

<script>
	import { Loading,Message } from 'element-ui';
	import Clipboard from 'clipboard';
			
	import ExchangeDialog from '@/components/exchange.vue';
	import guidance from '@/components/guidance.vue';
	import robotForm from '@/components/robotForm.vue';
	import subTransfer from '@/components/subTransfer/subTransfer.vue';

	import {mapGetters} from 'vuex'
	import numFormat from '@/utils/methApi/numFormat';
	import typeFormat from '@/utils/methApi/typeFormat';
	import robotNameFormat from '@/utils/methApi/robotNameFormat';
	import timeFormat from '@/utils/methApi/timeFormat';
	
	// api
	import {
		getPankou,
		getCurrencyBalance,
		getOrdersList,
		tradeCancel,
		
		tradeCoin,
		batchTradeCoin,
		batchTradeCancel,

		calNumberDex,
		batchTradeDex,
		dexWalletsApprove,

		// getCollectList,
		collect,
		collectCancel,
		
		getRobotList,

		getNFTCollection
	} from '@/api/markets'
	import { setRobot,delRobot } from '@/api/robot';

	// K线图
	// import {getOverrides,getStudiesOverrides} from '@/utils/themes'
	import KLineWidget from "../../components/KLineWidget";

	var that;
	let timeR,timeR2;

	export default ({
		name: 'stocks',
		components: {
			ExchangeDialog,
    		KLineWidget,
			guidance,
			robotForm,
			subTransfer
		},
		data() {
			return {
				// 新手指引
				// isFirst:true,
				position:'',//引导1定位
				position2:'',//引导234定位
				// 常用盘口
				popCollection:false,
				isCollection:false,
				// collectList:[],
				popexchange: localStorage.getItem('exchange') || (localStorage.getItem('lang')=='zh'?'币安':'bian'),
				popweb: localStorage.getItem('web') || 'bian',
				popyenUnit1: localStorage.getItem('yenUnit1') || 'btc',
				popyenUnit2: localStorage.getItem('yenUnit2') || 'usdt',
				popweb_id: localStorage.getItem('web_id')||'5',
				popweb_type:localStorage.getItem('web_type')||'0',//交易所类型 0-cex、1-dex

				tabIndex: 1,
				// handleInIsClick:false,
				isTool: false,
				showHelp: false,
				showHelp2: false,
				emptyImg: require('@/assets/images/noorder.png'),
				emptyImg2: require('@/assets/images/loading.png'),
				
				// 交易所选择
				popExchange: false,
				exchange: localStorage.getItem('exchange') || (localStorage.getItem('lang')=='zh'?'币安':'bian'),
				web: localStorage.getItem('web') || 'bian',
				yenUnit1: localStorage.getItem('yenUnit1') || 'btc',
				yenUnit2: localStorage.getItem('yenUnit2') || 'usdt',
				web_id: localStorage.getItem('web_id')||'5',
				web_type:localStorage.getItem('web_type')||'0',//交易所类型 0-cex、1-dex

				balance1:{},
				balance2:{},
				// 子钱包
				subBalance:{},
				subBalance2:{},
				
				// 量化工具
				toolList:['check','sale','revoke'],
				toolDexList:['calculate','transaction','empower'],//'balance','wallet','distribution','pool',
				toolNFTList:['check','sale'],
				selectTool:'check',

				// 此处数据仅用于测试,请自行更改获取数据
				orderList: [],//订单列表
				page:1,//订单page
				loading:false,
				load_disabled:false,
				toolform: {},//量化工具数据
				form: {},//手动下单数据

				widget:'',//K线对象

				// 盘口
				bidsList:[{price:0,amount:0}],
				asksList:[{price:0,amount:0}],

				// K线图
				symbol: "",
				symbolInfo: {},

				// 订单管理、机器人管理
				tab:0,//1
				// 机器人管理
				isRun:'0',
				selectItmeState:0,//选中项机器人对应状态
				robotList:[],
				typeIndex: null,
				showTips: false,
				activeIndex: null,
				closingRobot:{},//存放正在关闭倒数的机器人，及倒计时
				closingRobotList:[],//存放正在关闭倒数的机器人
				
				// 弹窗2
				showEdit: false,
				param:'',
				// 子组件的交易所
				isSonPop:false,
				web2: null,
				sonFormNum:0,

				// 资金划分
				address_list:[],
				id_list:[],
				chain:'',
				token:'',
				approve_token:'',
				transferWeb:'',
				transferExchange:'',

				// 移动端判定相关
				tool_box_show:false,

				// nft
				more:false,
				nftInfo:{
					collection_image:'',
					collection_name:'Not Found',
					contract_address:'Not Found',
					description:'Not Found',
					created_date:'Not Found',
					items:'0',
					owners:'0',
					total_volume:'0',
					floor_price:'0',
					average_price:'0',
					currency:'Not Found',
				},
			}
		},
		created(){
			that=this;

			var query=this.$route.query
			// 操盘机器人列表进入盘口
			if(query.web){
				this.popexchange=query.exchange
				this.popweb=query.web
				this.popyenUnit1=query.yenUnit1
				this.popyenUnit2=query.yenUnit2
				this.popweb_id=query.web_id
				this.popweb_type=query.web_type

				this.exchange=query.exchange
				this.web=query.web
				this.yenUnit1=query.yenUnit1
				this.yenUnit2=query.yenUnit2
				this.web_id=query.web_id
				this.web_type=query.web_type
			}
			// 添加机器人成功返回列表
			if(query.tab){
				this.tab=query.tab
			}

			// K线联调
			this.symbol=this.yenUnit1+'_'+this.yenUnit2;

			// 如果数据都存在则进入盘口
			// opensea
			if(this.web!='opensea'){
				if(!!this.web&&!!this.yenUnit1&&!!this.yenUnit2){
					this.handleIn('isCreated')
				}
			}else{
				if(!!this.web&&!!this.yenUnit1){
					this.handleIn_nft()
				}
			}
			// 获取收藏列表
			this.getCollectList()
		},
		mounted() {
			if(this.novice==1){
				// 新手引导元素1相对可视区x坐标
				this.$nextTick(()=>{
					this.getPosition();
				})
				window.addEventListener('resize',this.getPosition)
			}
		},
		computed:{
			// ,'needHelp','isFirstLogin'
			...mapGetters(['account','type','device','collectList','novice','helpNum','isMobile']),
			// showWelcome(){
			// 	return this.isFirst&&this.needHelp=='1'
			// }
			titleIndex(){
				return Number(this.typeIndex)+1
			},
			is0(){
				return (this.toolform.strategy==2)&&(!this.toolform.floatingRange||this.toolform.floatingRange==0)
			},
		},
		methods: {
			// 数字格式化-盘口数量保留小数加单位
			numFormat(num,type){
				return numFormat(num,type)
			},
			// dex批量交易-浮动范围 input变化
			floatChange(){
				var float=document.querySelector('#float input')
				this.toolform.floatingRange=float.value
			},
			// 复制
			handleCopy() {
				let code = this.nftInfo.contract_address;
				let clipboard = new Clipboard('.copyBtn', {
					text: () => {
						return code
					}
				})
				clipboard.on('success', () => {
					this.$message({
						message: this.$t('tip.copysuccess'),
						type: 'success',
						center: true,
						customClass: 'shotMsg',
					});
					clipboard.destroy();
				})
				clipboard.on('error', () => {
					this.$message({
						message: this.$t('tip.copyerr'),
						type: 'error',
						center: true,
						customClass: 'shotMsg',
					});
					clipboard.destroy();
				})
			},
			// 切换到-机器人管理 
			switchTab(){
				this.tab=1;
				// opensea
				if(this.web!='opensea'){
					this.getRobotList();
				}else{
					// 获取机器人列表
					this.getRobotList();
				}
			},
			// 判断引导定位
			getPosition(){
				// 可视区宽度
				var viewWith=window.innerWidth||document.documentElement.clientWidth;

				var element=document.querySelector('.relative');
				var x1=element?.offsetLeft;
				// 按钮定位至可视区边界的距离
				var x1_width=viewWith - x1;
				// 如果新手引导1放不下
				if(x1<200||x1_width>=320){
					this.position="left"
				}else if(x1_width<220){
					this.position="right"
				}else{
					this.position="center"
				}
				// 如果新手引导234放不下
				if(viewWith>500){
					if(this.device=='mobile'){
						this.position2="right"
					}else{
						this.position2="right0"
					}
				}else{
					this.position2="bottom"
				}
			},
			// 获取当前盘口机器人列表
			getRobotList(){
				const loading = Loading.service({
					target: '.main',
					text: 'Loading',
					spinner: 'el-icon-loading',
					background: 'rgba(0, 0, 0, 0.7)',
					customClass: 'apploading',
				});
				if(this.web!='opensea'){
					var currency=this.yenUnit1+'_'+this.yenUnit2;
				}else{
					var currency=this.yenUnit1;
				}
				getRobotList({
					account:this.account,
					state:this.isRun,
					web:this.web,
					currency
				}).then(res=>{
					if(!!res){
						res.forEach(item=>{
							item.typeIndex=robotNameFormat(item.type,item.sub_type)
						})
					}
					this.robotList=res
					// console.log(res)
					loading.close();
				}).catch(err=>{
					loading.close();
				})
			},
			// 打开提示弹窗
			openTip(index){
				this.typeIndex=index;
				this.showTips = true;
			},
			// 参数设置字段格式化
			paramFormat(item){
				var dataArr=item.param?.split(' ');
				if(item.type=='buy_sell'){
                	// 高抛低吸机器人
					return this.$t('robot.higherthan')+dataArr[0]+this.$t('robot.sellout')+', '+this.$t('robot.below')+dataArr[1]+this.$t('robot.purchase')+', '+this.$t('robot.interval')+dataArr[2]+this.$t('robot.second')+', '+this.$t('robot.per')+dataArr[3]+(dataArr[4]==1? ', '+this.$t('robot.opponents'):'')+', '+this.$t('robot.maximum')+dataArr[5]+', '+this.$t('robot.minimum')+dataArr[6]+(dataArr[7]==1?', '+this.$t('robot.working')+this.$t('robot.chuck'):'')
				}else if(item.type=='iceberg'){
                	// 分批卖出机器人
					return this.$t('robot.floorPrice')+dataArr[0]+', '+this.$t('robot.interval2')+dataArr[1]+this.$t('robot.second')+', '+this.$t('robot.quantityPerTime')+dataArr[2]+', '+this.$t('robot.frequency')+dataArr[3]
				}else if(item.type=='banzhuan'){
                	// 搬砖机器人
					return this.$t('robot.targetExchange')+dataArr[0]+', '+this.$t('robot.currentCharge')+dataArr[1]+'%, '+this.$t('robot.targetCharge')+dataArr[2]+'%, '+this.$t('robot.priceDecimal')+dataArr[3]+', '+this.$t('robot.quantityDecimal')+dataArr[4]
				}else if(item.type=='wangge'){
                	// 马丁策略量化机器人
					// return this.$t('robot.openingAmount')+dataArr[0]+this.yenUnit2+', '+this.$t('robot.repTimes')+dataArr[1]+this.$t('robot.ci')+', '+this.$t('robot.repDifferences')+dataArr[2]+'%, '+this.$t('robot.interferenceRange')+dataArr[3]+'%'
					return this.$t('robot.openingAmount')+dataArr[0]+item.currency.split('_')[1]+', '+this.$t('robot.repTimes')+dataArr[1]+this.$t('robot.ci')+', '+this.$t('robot.interferenceRange')+dataArr[2]+'%, '+this.$t('robot.stopBackRatio')+dataArr[3]+'%, '+this.$t('robot.repDifferences')+dataArr[4]+'%, '+this.$t('robot.addBackRatio')+dataArr[5]+'%, '+(dataArr[6]==1?this.$t('robot.firstDouble')+', ':'')+this.$t('robot.addMultiple')+dataArr[7]+this.$t('robot.times')
				}
				// else if(item.type=='grid'){
                // 	// 网格策略量化机器人-旧
				// 	return this.$t('robot.priceRange')+dataArr[0]+'~'+dataArr[1]+', '+this.$t('robot.grids')+dataArr[2]+', '+this.$t('robot.perGear')+dataArr[3]+', '+this.$t('robot.maintain')+dataArr[4]+', '+this.$t('robot.stopPrice')+dataArr[5]
				// }
				else if(item.type=='grid'){
				// else if(item.type=='wave'){
                	// 网格策略量化机器人
					return this.$t('robot.firstAmount')+dataArr[0]+item.currency.split('_')[1]+', '+
					this.$t('robot.grids')+dataArr[1]+', '+
					this.$t('robot.stopRatio')+dataArr[2]+'%, '+
					this.$t('robot.addInterval')+dataArr[3]+'%, '+
					this.$t('robot.addIntervalIncrease')+dataArr[4]+this.$t('robot.times')+', '+
					this.$t('robot.addAmountIncrease')+dataArr[5]+this.$t('robot.times')
				}


				else if(item.type=='shua'){
                	// 成交量对敲机器人
					return this.$t('robot.counterQuantity')+dataArr[0]+'~'+dataArr[1]+', '+this.$t('robot.perMinute')+dataArr[2]+'~'+dataArr[3]+this.$t('robot.ci')+', '+this.$t('robot.priceDecimal')+dataArr[4]
				}else if(item.type=='budan_new'){
                	// 盘口管理机器人
					return this.$t('robot.orderPerNum')+dataArr[0]+', '+this.$t('robot.sellPerNum')+dataArr[1]+', '+this.$t('robot.difference')+dataArr[2]+'%, '+this.$t('robot.perDifference')+dataArr[3]+'%, '+this.$t('robot.priceDecimal')+dataArr[4]+', '+this.$t('robot.maintenance')+dataArr[5]
				}else if(item.type=='hupan'){
                	// 护盘机器人 买盘、卖盘
					return this.$t('robot.priceRange')+dataArr[0]+'~'+dataArr[1]+', '+this.$t('robot.protective')+dataArr[2]+', '+this.$t('robot.gear')+dataArr[3]+this.$t('robot.priceDecimal')+dataArr[4]
				}else if(item.type=='push'){
                	// 趋势推动机器人
					// return this.$t('robot.plan')+dataArr[0]+', '+this.$t('robot.intervalRange')+dataArr[1]+'~'+dataArr[2]+this.$t('robot.random')+', '+this.$t('robot.perInterval')+dataArr[3]+', '+this.$t('robot.startPrice')+dataArr[4]+', '+this.$t('robot.targetPrice')+dataArr[5]
					return (item.sub_type=='bids'?this.$t('robot.buyBtn'):$t('robot.sellBtn'))+', '+
					this.$t('robot.plan')+dataArr[0]+' '+
					(item.sub_type=='bids'?item.currency.split('_')[1]:item.currency.split('_')[0])+', '+
					this.$t('robot.plan')+dataArr[0]+', '+this.$t('robot.endTime')+timeFormat(Number(dataArr[1]),'dateTime')+', '+this.$t('robot.startPrice')+dataArr[2]+', '+this.$t('robot.targetPrice')+dataArr[3]+', '+this.$t('robot.priceDecimal')+dataArr[4]
				}else if(item.type=='hugevol'){
                	// 成交放量机器人
					return this.$t('robot.tradingVolume')+dataArr[0]+'/'+this.$t('robot.minute')+', '+
					this.$t('robot.workingHours')+dataArr[1]+this.$t('robot.minutes')+', '+
					this.$t('robot.splitBudget')+dataArr[2]+item.currency.split('_')[1]+', '+
					this.$t('robot.priceDecimal')+dataArr[3]
				}
				
				else if(item.type=='draw'){
                	// 画线机器人
					return this.$t('robot.upperLimit')+dataArr[0]+', '+this.$t('robot.lowerLimit')+dataArr[1]+', '+this.$t('robot.difference')+dataArr[2]+'%, '+this.$t('robot.perDifference')+dataArr[3]+'%, '+this.$t('robot.buy')+dataArr[4]+', '+this.$t('robot.offers')+dataArr[5]+', '+this.$t('robot.singleMax')+dataArr[6]+', '+this.$t('robot.priceDecimal')+dataArr[7]+', '+this.$t('robot.swiping')+dataArr[8]+this.$t('robot.individual')
				}else if(item.type=='real_genpan'){
                	// 跟盘机器人
					return (item.sub_type=='rebalance'?this.$t('robot.rebalance'):this.$t('robot.standard'))+', '+this.$t('robot.targetDisk')+dataArr[0]+', '+this.$t('robot.difference')+dataArr[1]+'%, '+this.$t('robot.perDifference')+dataArr[2]+'%, '+this.$t('robot.buy')+dataArr[3]+', '+this.$t('robot.offers')+dataArr[4]+', '+this.$t('robot.singleMax')+dataArr[5]+', '+this.$t('robot.priceDecimal')+dataArr[6]+', '+this.$t('robot.swiping')+dataArr[7]+this.$t('robot.individual')
				}else if(item.type=='kongpan'){
                	// 控盘机器人
					return item.currency.split('_')[0]+this.$t('robot.budget')+dataArr[0]+', '+item.currency.split('_')[1]+this.$t('robot.budget')+dataArr[1]+', '+this.$t('robot.target')+dataArr[2]+', '+this.$t('robot.difference')+dataArr[3]+', '+this.$t('robot.paySmall')+dataArr[4]+', '+this.$t('robot.sellSmall')+dataArr[5]+', '+this.$t('robot.perDifference')+dataArr[6]+', '+this.$t('robot.maintenance')+dataArr[7]+', '+this.$t('robot.payMedium')+dataArr[8]+', '+this.$t('robot.sellMedium')+dataArr[9]+', '+this.$t('robot.perDifference')+dataArr[10]+', '+this.$t('robot.maintenance')+dataArr[11]+', '+this.$t('robot.payLarge')+dataArr[12]+', '+this.$t('robot.sellLarge')+dataArr[13]+', '+this.$t('robot.perDifference')+dataArr[14]+', '+this.$t('robot.maintenance')+dataArr[15]+', '+this.$t('robot.priceDecimal')+dataArr[16]
				}else if(item.type=='real_genpan_quick'){
                	// 主流币跟盘机器人
					return this.$t('robot.targetDisk')+dataArr[0]+', '+this.$t('robot.difference')+dataArr[1]+'%, '+this.$t('robot.perDifference')+dataArr[2]+'%, '+this.$t('robot.buy')+dataArr[3]+', '+this.$t('robot.offers')+dataArr[4]+', '+this.$t('robot.priceDecimal')+dataArr[5]+', '+this.$t('robot.swiping')+dataArr[6]+this.$t('robot.individual')+', '+this.$t('robot.hop')+dataArr[7]
				}

				else if(item.type=='shua_dex'){
                	// Dex画线机器人
					return this.$t('robot.priceRange')+dataArr[0]+'~'+dataArr[1]+', '+this.$t('robot.transaction')+dataArr[2]+'~'+dataArr[3]+this.$t('robot.individual')+', '+this.$t('robot.intervalTime')+dataArr[4]+this.$t('robot.second')+', '+this.$t('robot.direction')+(dataArr[5]==0?this.$t('robot.oscillation'):dataArr[5]==1?this.$t('robot.rise'):dataArr[5]==2?this.$t('robot.fall'):dataArr[5])
				}else if(item.type=='real_genpan_dex'){
                	// Dex跟盘机器人
					return this.$t('robot.targetDisk')+dataArr[0]+', '+this.$t('robot.targetPair')+dataArr[1]+'/'+dataArr[2]+', '+this.$t('robot.scaling')+dataArr[3]+'%, '+this.$t('robot.intervalTime')+dataArr[4]+this.$t('robot.second')+', '+this.$t('robot.transactionMax')+dataArr[4]+this.$t('robot.individual')
				}else if(item.type=='buy_sell_dex'){
                	// Dex高抛低吸机器人
					return this.$t('robot.higherthan')+dataArr[0]+this.$t('robot.sellout')+', '+this.$t('robot.below')+dataArr[1]+this.$t('robot.purchase')+', '+this.$t('robot.interval')+dataArr[2]+this.$t('robot.second')+', '+this.$t('robot.per')+dataArr[3]+item.currency.split('_')[0]+', '+this.$t('robot.maximum')+dataArr[4]+item.currency.split('_')[0]+', '+this.$t('robot.minimum')+dataArr[5]+item.currency.split('_')[0]
				}else if(item.type=='frontrun_dex'){
                	// Dex抢买机器人
					return this.$t('robot.trigger')+dataArr[0]+item.currency.split('_')[1]+', '+this.$t('robot.everyPurchase')+dataArr[1]+item.currency.split('_')[1]+(dataArr[2]==1? ', '+this.$t('robot.isAutoSell'):'')
				}else if(item.type=='snipe_dex'){
                	// Dex狙击新盘
					// return this.$t('robot.purchase')+dataArr[0]+item.currency.split('_')[1]+(dataArr[1]? ', '+this.$t('robot.isAutoSell'):'')
					return this.$t('robot.contract')+dataArr[0]+', '+
					this.$t('robot.orderAmount')+dataArr[1]+item.currency.split('_')[1]+', '+
					this.$t('robot.multiple')+dataArr[2]+this.$t('robot.times')+', '+
					this.$t('robot.openingTime')+dataArr[3]+', '+
					(dataArr[4]==1? this.$t('robot.taxDeduction'):this.$t('robot.noTaxDeduction'))
				}else if(item.type=='banzhuan_dex'){
                	// DEX到CEX搬砖
					return this.$t('robot.targetExchange')+'(CEX)'+dataArr[0]+', '+this.$t('robot.currentCharge')+dataArr[1]+'%, '+this.$t('robot.targetCharge')+dataArr[2]+'%, '+this.$t('robot.priceDecimal')+dataArr[3]+', '+this.$t('robot.quantityDecimal')+dataArr[4]
				}
				else if(item.type=='shua_nft'){
                	// NFT画线机器人
					return this.$t('robot.priceRange')+dataArr[0]+'~'+dataArr[1]+', '+this.$t('robot.transaction')+dataArr[2]+'~'+dataArr[3]+this.$t('robot.individual')+', '+this.$t('robot.intervalTime')+dataArr[4]+this.$t('robot.second')+', '+this.$t('robot.direction')+(dataArr[5]==0?this.$t('robot.oscillation'):dataArr[5]==1?this.$t('robot.rise'):dataArr[5]==2?this.$t('robot.fall'):dataArr[5])
				}else if(item.type=='buy_sell_nft'){
                	// NFT高抛低吸机器人
					return this.$t('robot.higherthan')+dataArr[0]+this.$t('robot.sellout')+', '+this.$t('robot.below')+dataArr[1]+this.$t('robot.purchase')+', '+this.$t('robot.interval')+dataArr[2]+this.$t('robot.second')+', '+this.$t('robot.per')+dataArr[3]+item.currency.split('_')[0]+', '+this.$t('robot.maximum')+dataArr[4]+item.currency.split('_')[0]+', '+this.$t('robot.minimum')+dataArr[5]+item.currency.split('_')[0]
				}
			},
			// 编辑
			handleEdit(item,index) {
				// console.log('item',item)
				// 选中项
				this.activeIndex = index;
				// 机器人对应项
				this.typeIndex=item.typeIndex;
				this.selectItmeState=item.state;

				var currency=item.currency.split('_')

				this.web=item.web;
				this.exchange=item.web;
				this.yenUnit1=currency[0];
				this.yenUnit2=currency[1];
				this.param=item.param;
				// 弹窗
				this.showEdit=true;
				// 重新获取参数-传递param给组件 getRobot
				this.$nextTick(()=>{
					if(this.$refs.robotForm){
						if(
							(this.titleIndex==10&&item.sub_type=='asks')||
							(this.titleIndex==12&&item.sub_type=='rebalance')
						){
							// 传入默认单选项
							this.$refs.robotForm.paramInit(this.param,2);
						}else{
							this.$refs.robotForm.paramInit(this.param);
						}
					}
				});
			},
			beforeClose(done){
				this.activeIndex = null;
				done()
			},
			// 弹窗
			handleEditCancel() {
				this.showEdit = false;
			},
			handleConfirm(data,approve_type) {
				var param=data[0];
				var addSelectNum=data[1];
				var isRun=data[2];
				// console.log('param',param,addSelectNum)

				var index=this.typeIndex;
				var {type,sub_type}=typeFormat(index,addSelectNum)
				
				var currency
				if(this.web!='opensea'){
					currency=this.yenUnit1+'_'+this.yenUnit2;
				}else{
					currency=this.yenUnit1;
				}
				
				// 如果保存并启动，则判断开启的机器人是否在关闭倒计时机器人中
				if(!!isRun&&this.closingRobotList.indexOf(this.typeIndex)>=0){
					// 正在关闭中，请稍后..
					this.$message({
						message: this.$t('tip.closingErr'),
						center: true,
						type: 'error',
						customClass: 'shotMsg',
					});
				}else{
					setRobot({
						web:this.web,
						currency,
						account:this.account,
						type:type,
						sub_type:sub_type,
						param:param,
						state:!!isRun?1:this.selectItmeState,
						approve_type:approve_type=='again'?'0':'1'
					}).then(res=>{
						if(res.code==2107||(res.code==2109&&res.type=='now')){
							// 未授权
							// 提示授权中。。
							this.$message({
								message: this.$t('tip.processing'),
								type: 'success',
								center: true,
								customClass: 'shotMsg',
							});
							// 授权代币请求
							dexWalletsApprove({
								web:this.web,
								account:this.account,
								approve_token:res.data.replace(',','_'),//代币
								is_master_approve:2,
							}).then(re=>{
								if(re.code==2172&&re.type=='confirm'){
									// 分拨授权
									this.address_list=re.data.address_list
									this.id_list=re.data.id_list
									this.chain=re.data.chain
									this.token=re.data.gas_token
									this.approve_token=re.data.approve_token
									this.transferWeb=this.web
									this.transferExchange=this.exchange
									this.$refs.subTransfer.open()
								}else if(re.code==2172&&re.type=='ignore'){
									// 忽略，重新操作
									this.handleConfirm(data,'again')
								}else{
									// 授权成功
									this.$confirm(this.$t('tip.authorizeSuc'), this.$t('tip.tips'), {
										confirmButtonText: this.$t('common.confirm'),//'继续操作',
										// showConfirmButton:false,
										center: true,
										showClose: false,
										customClass: 'deleteOk',
										// cancelButtonText: this.$t('common.back'),
										showCancelButton:false,
									})
								}
							})
						}else if(res.code==2109&&res.type=='ignore'){
							// 忽略，重新操作
							this.handleConfirm(data,'again')
						}else if(res.code==2109&&res.type=='confirm'){
							// 分拨授权
							this.address_list=res.data.address_list
							this.id_list=res.data.id_list
							this.chain=res.data.chain
							this.token=res.data.gas_token
							this.approve_token=res.data.approve_token
							this.transferWeb=this.web
							this.transferExchange=this.exchange
							this.$refs.subTransfer.open()
						}else{
							this.getRobotList()
							// 保存--成功
							this.$message({
								message: this.$t('tip.saveSuc'),
								center: true,
								type: 'success',
								customClass: 'shotMsg',
							});
							this.showEdit = false;
							this.activeIndex = null;
						}
					})
				}
			},
			handleStart(item,index,approve_type) {
				this.activeIndex = index;
				this.$confirm(this.$t('tip.startRobot'), this.$t('tip.tips'), {
					confirmButtonText: this.$t('common.confirm2'),
					cancelButtonText: this.$t('common.back'),
					center: true,
					showClose: false,
					customClass: 'deleteOk',
				}).then(() => {
					this.activeIndex = null;
					var data=Object.assign({},item)
					data.state=1;
					data.approve_type=approve_type=='again'?'0':'1'
					if(this.closingRobotList.indexOf(item.typeIndex)>=0){
						// 正在关闭中，请稍后..
						this.$message({
							message: this.$t('tip.closingErr'),
							center: true,
							type: 'error',
							customClass: 'shotMsg',
						});
					}else{
						setRobot(data).then(res=>{
							if(res.code==2107||(res.code==2109&&res.type=='now')){
								// 未授权
								// 提示授权中。。
								this.$message({
									message: this.$t('tip.processing'),
									type: 'success',
									center: true,
									customClass: 'shotMsg',
								});
								// 授权代币请求
								dexWalletsApprove({
									web:data.web,
									account:this.account,
									approve_token:res.data.replace(',','_'),//代币
									is_master_approve:2,
								}).then(re=>{
									if(re.code==2172&&re.type=='confirm'){
										// 分拨授权
										this.address_list=re.data.address_list
										this.id_list=re.data.id_list
										this.chain=re.data.chain
										this.token=re.data.gas_token
										this.approve_token=re.data.approve_token
										this.transferWeb=this.web
										this.transferExchange=this.exchange
										this.$refs.subTransfer.open()
									}else if(re.code==2172&&re.type=='ignore'){
										// 忽略，重新操作
										this.handleStart(item,index,'again')
									}else{
										// 授权成功
										this.$confirm(this.$t('tip.authorizeSuc'), this.$t('tip.tips'), {
											confirmButtonText: this.$t('common.confirm'),//'继续操作',
											// showConfirmButton:false,
											center: true,
											showClose: false,
											customClass: 'deleteOk',
											// cancelButtonText: this.$t('common.back'),
											showCancelButton:false,
										})
									}
								})
							}else if(res.code==2109&&res.type=='ignore'){
								// 忽略，重新操作
								this.handleStart(item,index,'again')
							}else if(res.code==2109&&res.type=='confirm'){
								// 分拨授权
								this.address_list=res.data.address_list
								this.id_list=res.data.id_list
								this.chain=res.data.chain
								this.token=res.data.gas_token
								this.approve_token=res.data.approve_token
								this.transferWeb=data.web
								this.transferExchange=data.exchange
								this.$refs.subTransfer.open()
							}else{
								this.getRobotList()
								// 启动--成功
								this.$message({
									message: this.$t('tip.startSuc'),
									center: true,
									type: 'success',
									customClass: 'shotMsg',
								});
							}
						})
					}
				}).catch(() => {
					// 取消
					this.activeIndex = null;
				});
			},
			handleStop(item,index,approve_type) {
				this.activeIndex = index;
				this.$confirm(this.$t('tip.stopRobot'), this.$t('tip.tips'), {
					confirmButtonText: this.$t('common.confirm2'),
					cancelButtonText: this.$t('common.back'),
					center: true,
					showClose: false,
					customClass: 'deleteOk',
				}).then(() => {
					this.activeIndex = null;
					var data=Object.assign({},item)
					data.state=0;
					data.approve_type=approve_type=='again'?'0':'1'
					setRobot(data).then(res=>{
						if(res.code==2107||(res.code==2109&&res.type=='now')){
							// 未授权
							// 提示授权中。。
							this.$message({
								message: this.$t('tip.processing'),
								type: 'success',
								center: true,
								customClass: 'shotMsg',
							});
							// 授权代币请求
							dexWalletsApprove({
								web:data.web,
								account:this.account,
								approve_token:res.data.replace(',','_'),//代币
								is_master_approve:2,
							}).then(re=>{
								if(re.code==2172&&re.type=='confirm'){
									// 分拨授权
									this.address_list=re.data.address_list
									this.id_list=re.data.id_list
									this.chain=re.data.chain
									this.token=re.data.gas_token
									this.approve_token=re.data.approve_token
									this.transferWeb=this.web
									this.transferExchange=this.exchange
									this.$refs.subTransfer.open()
								}else if(re.code==2172&&re.type=='ignore'){
									// 忽略，重新操作
									this.handleStop(item,index,'again')
								}else{
									// 授权成功
									this.$confirm(this.$t('tip.authorizeSuc'), this.$t('tip.tips'), {
										confirmButtonText: this.$t('common.confirm'),//'继续操作',
										// showConfirmButton:false,
										center: true,
										showClose: false,
										customClass: 'deleteOk',
										// cancelButtonText: this.$t('common.back'),
										showCancelButton:false,
									})
								}
							})
						}else if(res.code==2109&&res.type=='ignore'){
							// 忽略，重新操作
							this.handleStop(item,index,'again')
						}else if(res.code==2109&&res.type=='confirm'){
							// 分拨授权
							this.address_list=res.data.address_list
							this.id_list=res.data.id_list
							this.chain=res.data.chain
							this.token=res.data.gas_token
							this.approve_token=res.data.approve_token
							this.transferWeb=data.web
							this.transferExchange=data.exchange
							this.$refs.subTransfer.open()
						}else{
							this.getRobotList()
							// 停止--成功
							this.$message({
								message: this.$t('tip.stopSuc'),
								center: true,
								type: 'success',
								customClass: 'shotMsg',
							});
							// 如果停止成功 则提示10S倒计时自动关闭，并加入closingRobot
							this.closingRobotList.push(item.typeIndex)
							this.$set(this.closingRobot,item.typeIndex,10)
							var timeR=setInterval(()=>{
								var typeIndex=item.typeIndex
								if(this.closingRobot[typeIndex]>0){
									var time=this.closingRobot[typeIndex]-1
									this.$set(this.closingRobot,typeIndex,time)
								}else{
									this.closingRobotList.splice(this.closingRobotList.indexOf(time),1)
									clearInterval(timeR)
								}
							},1000)
						}
					})
				}).catch(() => {
					// 取消
					this.activeIndex = null;
				});
			},
			handleDelete(item,index) {
				this.activeIndex = index;
				this.$confirm(this.$t('tip.delTip'), this.$t('tip.tips'), {
					confirmButtonText: this.$t('common.confirm2'),
					cancelButtonText: this.$t('common.back'),
					center: true,
					showClose: false,
					customClass: 'deleteOk',
				}).then(() => {
					this.activeIndex = null;
					delRobot({
						web:item.web,
						currency:item.currency,
						account:item.account,
						robot_id:item.id
					}).then(res=>{
						this.getRobotList()

						// 删除--成功
						this.$message({
							message: this.$t('tip.delSuc'),
							center: true,
							type: 'success',
							customClass: 'shotMsg',
						});
					})
				}).catch(() => {
					// 取消
					this.activeIndex = null;
				});
			},
			
			// 子组件交易所
			openPop(data,num){
				// 暂存原交易所数据
				this.web2 = this.web;
				// 子组件交易所数据展示
				this.web=data.web;

				this.isSonPop=true;
				this.popExchange = true;
				if(num){
					this.sonFormNum=num;
				}
			},

			// 交易记录
			jumpRecord(){
				this.$router.push({
					path:'/record'
				})
			},
			// 打开选择盘口
			openPopCollect(){
				// 引导状态下不能点击,移动端先例外
				if(this.novice==1&&this.isMobile==0){
					return
				}
				this.popexchange=this.exchange
				this.popweb=this.web
				this.popyenUnit1=this.yenUnit1
				this.popyenUnit2=this.yenUnit2
				this.popweb_id=this.web_id
				this.popCollection=true
			},
			// 常用填充
			commonlyTc(item){
				this.popweb=item.web;
				this.popyenUnit1=item.currency.split('_')[0];
				this.popyenUnit2=item.currency.split('_')[1];
				this.popexchange=item.exchange_name;
				this.popweb_id=item.web_id;
				this.popweb_type=item.web_type;
				this.handleInConfirm()
			},
			// 确认进入盘口
			handleInConfirm(){
				// 非空验证
				if(!this.popweb){
					this.$message({
						message:this.$t('tip.selectExchange')+'！',
						type: 'error',
						center: true,
						customClass: 'shotMsg',
					});
				}
				else if(this.popweb!='opensea'){
					if(!this.popyenUnit1||!this.popyenUnit2){
						this.$message({
							message:this.$t('tip.enterPair')+'！',
							type: 'error',
							center: true,
							customClass: 'shotMsg',
						});
					}else{
						var isCreated;
						if(this.web=='opensea'){
							isCreated='isCreated'
						}
						
						this.exchange=this.popexchange
						this.web=this.popweb
						this.yenUnit1=this.popyenUnit1
						this.yenUnit2=this.popyenUnit2
						this.web_id=this.popweb_id
						this.web_type=this.popweb_type
						this.handleIn(isCreated);
						// 判定是否收藏
						this.judgmentColl()
						this.popCollection=false;
					}
				}else{
					// opensea
					if(!this.popyenUnit1){
						this.$message({
							message:this.$t('tip.enterCollect')+'！',
							type: 'error',
							center: true,
							customClass: 'shotMsg',
						});
					}else{
						this.exchange=this.popexchange
						this.web=this.popweb
						this.yenUnit1=this.popyenUnit1
						this.yenUnit2=''
						this.web_id=this.popweb_id
						this.web_type=this.popweb_type
						this.handleIn_nft();
						// 判定是否收藏
						this.judgmentColl()
						this.popCollection=false;
					}
				}
			},
			// 进入盘口
			handleIn(isCreated) {
				// 缓存交易所、交易对
				localStorage.setItem('exchange', this.exchange)
				localStorage.setItem('web', this.web)
				localStorage.setItem('yenUnit1', this.yenUnit1)
				localStorage.setItem('yenUnit2', this.yenUnit2)
				localStorage.setItem('web_id', this.web_id)
				localStorage.setItem('web_type', this.web_type)
				// 如果移动端判定，根据cex/dex默认工具
				if(this.isMobile==1){
					this.web_type==0?this.selectTool='check':this.selectTool='calculate';
				}

				// 初始化数据
				this.page=1;
				this.loading=false;
				this.load_disabled=false;

				// 获取 账户余额、订单列表 并重置轮询
				this.getData()
				// 获取普通盘口并重置轮询
				this.getPankouAll()
				if(this.tab==1){
					// 获取当前盘口机器人管理列表
					this.getRobotList()
				}
				if(isCreated!='isCreated'){
					// K线联调
					this.onClick(this.yenUnit1+'_'+this.yenUnit2,this.web)
				}

				// 订单管理、机器人管理选择初始化
				if(this.web_type==1){
					this.switchTab()
				}else{
					this.tab=0
				}
			},
			// 进入盘口-opensea
			handleIn_nft() {
				// 缓存交易所、交易对
				localStorage.setItem('exchange', this.exchange)
				localStorage.setItem('web', this.web)
				localStorage.setItem('yenUnit1', this.yenUnit1)
				localStorage.setItem('yenUnit2', this.yenUnit2)
				localStorage.setItem('web_id', this.web_id)
				localStorage.setItem('web_type', this.web_type)
				// 如果移动端判定，根据cex/dex默认工具
				if(this.isMobile==1){
					this.selectTool='check';
				}

				// 初始化数据
				this.page=1;
				this.loading=false;
				this.load_disabled=false;

				// 获取 账户余额、订单列表 并重置轮询
				this.getData()
				// 获取普通盘口并重置轮询
				this.getPankouAll()
				if(this.tab==1){
					// 获取当前盘口机器人管理列表
					this.getRobotList()
				}

				// 获取NFT图片及最低价等信息
				this.getNFTCollection()

				// 订单管理、机器人管理选择初始化
				if(this.web_type==1){
					this.switchTab()
				}else{
					//订单 及 opensea
					this.tab=0
				}
			},

			// 选中普通盘口 快捷填入数据-手动下单
			selectData(item){
				// console.log(item)
				this.form.price=item.price;
				this.form.number=item.amount;
			},
			// 帮助已知晓
			// tipCheck(){
				// this.isFirst=false;
				// this.$store.commit('user/SET_NEED_HELP', '0')
				// localStorage.setItem('needHelp', '0');
			// },
			// 切换交易对
			onClick(symbol,web) {
				this.$nextTick(()=>{
					this.$refs.kLineWidget?.setSymbol(symbol,web);
				})
			},
			// 获取收藏列表
			getCollectList(){
				this.$store.dispatch('markets/getCollectList').then((data) => {
					this.judgmentColl()
				});
			},
			// 收藏盘口切换 收藏/取消收藏
			toCollection(){
				// this.isCollection=!this.isCollection
				if(this.web!='opensea'){
					var currency=this.yenUnit1+'_'+this.yenUnit2
				}else{
					var currency=this.yenUnit1
				}
				if(!this.isCollection){
					collect({
						account:this.account,
						currency,
						web_id:this.web_id,
						web:this.web,
					}).then(res=>{
						this.$message({
							message: this.$t('tip.collectionSuc'),
							type: 'success',
							center: true,
							customClass: 'shotMsg',
						});
						this.isCollection=!this.isCollection
						this.getCollectList()
					})
				}else{
					collectCancel({
						account:this.account,
						currency,
						web_id:this.web_id,
						web:this.web
					}).then(res=>{
						this.$message({
							message: this.$t('tip.cancelCollectionSuc'),
							type: 'success',
							center: true,
							customClass: 'shotMsg',
						});
						this.isCollection=!this.isCollection
						this.getCollectList()
					})
				}
			},
			// 判定是否收藏
			judgmentColl(){
				var isCollection=false
				// 判断当前盘口是否已收藏
				if(!!this.collectList&&this.collectList.length>0){
					if(this.web!='opensea'){
						var currencyNow=(this.yenUnit1+'_'+this.yenUnit2).toUpperCase()
					}else{
						var currencyNow=this.yenUnit1.toUpperCase()
					}
					this.collectList.forEach(item=>{
						var currency=item.currency.toUpperCase()
						if(item.web_id==this.web_id&&currencyNow==currency){
							isCollection=true
						}
					})
				}else{
					isCollection=false
				}
				this.isCollection=isCollection
			},
			// 取消收藏
			collectCancel(currency,web_id,web){
				collectCancel({
					account:this.account,
					currency,
					web_id,
					web,
				}).then(res=>{
					this.$message({
						message: this.$t('tip.cancelCollectionSuc'),
						type: 'success',
						center: true,
						customClass: 'shotMsg',
					});
					this.getCollectList()
				})
			},

			// 获取NFT图片及最低价等信息
			getNFTCollection(){
				getNFTCollection({
					web:this.web,
					currency:this.yenUnit1,
					account:this.account,
				}).then(res=>{
					// console.log(res)
					this.nftInfo=res;
				}).catch(err=>{
					// 未找到
					this.nftInfo={
						collection_image:'',
						collection_name:'Not Found',
						contract_address:'Not Found',
						description:'Not Found',
						created_date:'Not Found',
						items:'0',
						owners:'0',
						total_volume:'0',
						floor_price:'0',
						average_price:'0',
						currency:'Not Found',
					}
				})
			},
			// 获取 账户余额、订单列表 并重置轮询
			getData(){
				// 初始化数据
				this.page=1;
				this.loading=false;
				this.load_disabled=false;
				if(this.web!='opensea'){
					var currency=this.yenUnit1+'_'+this.yenUnit2;
					// 获取账户交易对余额
					getCurrencyBalance({
						web:this.web,
						account:this.account,
						currency,
					}).then(res => {
						// console.log(res)
						this.balance1=res.main_balance[this.yenUnit1.toUpperCase()];
						this.balance2=res.main_balance[this.yenUnit2.toUpperCase()];
						// 子钱包
						this.subBalance=res.attached_balance

						// 循环获取钱包 60000
						if(timeR2){
							clearInterval(timeR2)
							timeR2=''
						}
						timeR2=setInterval(()=>{
							getCurrencyBalance({
								web:this.web,
								account:this.account,
								currency,
							}).then(res=>{
								// 获取账户交易对余额
								this.balance1=res.main_balance[this.yenUnit1.toUpperCase()];
								this.balance2=res.main_balance[this.yenUnit2.toUpperCase()];
								// 子钱包
								this.subBalance=res.attached_balance
							})
						},60000)
					})
				
					// 订单列表
					getOrdersList({
						web:this.web,
						account:this.account,
						currency,
						page:this.page,
					}).then(res => {
						this.orderList=res;
					})
				}else{
					var currency=this.yenUnit1;
					// 获取账户交易对余额
					getCurrencyBalance({
						web:this.web,
						account:this.account,
						currency,
					}).then(res => {
						// console.log(res)
						this.balance1=res.main_balance;
						this.balance2=res.main_nft_assets;
						// 子钱包
						this.subBalance=res.attached_balance
						this.subBalance2=res.attached_nft_assets

						// 循环获取钱包 60000
						if(timeR2){
							clearInterval(timeR2)
							timeR2=''
						}
						timeR2=setInterval(()=>{
							getCurrencyBalance({
								web:this.web,
								account:this.account,
								currency,
							}).then(res=>{
								// 主钱包
								this.balance1=res.main_balance;
								this.balance2=res.main_nft_assets;
								// 子钱包
								this.subBalance=res.attached_balance
								this.subBalance2=res.attached_nft_assets
							})
						},60000)
					})
				}
			},
			// 获取普通盘口并重置轮询
			getPankouAll(){
				var currency
				if(this.web!='opensea'){
					currency=this.yenUnit1+'_'+this.yenUnit2;
					// 盘口
					getPankou({
						web:this.web,
						account:this.account,
						currency,
					}).then(res=>{
						if(!res){
							this.bidsList=[{
								price:0,
								amount:0
							}]
							this.asksList=[{
								price:0,
								amount:0
							}]
						}else{
							this.bidsList=!res.bids||!res.bids.length? [{price:0,amount:0}] : res.bids;
							this.asksList=!res.asks||!res.asks.length?[{price:0,amount:0}]:res.asks.reverse();//res.asks;
						}
						// 卖盘滚动条移至底部
						this.$nextTick(()=>{
							var sell_end=document.getElementById('sell_end');
							sell_end?.scrollIntoView(false)
						});
					})
				}else{
					// 获取opensea盘口信息
					currency=this.yenUnit1;
					// 请求
					this.bidsList=[{
						price:0,
						amount:0
					}]
					this.asksList=[{
						price:0,
						amount:0
					}]
				}

				// 循环获取盘口 15000
				if(timeR){
					clearInterval(timeR)
					timeR=''
				}
				timeR=setInterval(()=>{
					if(this.web!='opensea'){
						// 盘口
						getPankou({
							web:this.web,
							account:this.account,
							currency,
						}).then(res=>{
							if(!res){
								this.bidsList=[{
									price:0,
									amount:0
								}]
								this.asksList=[{
									price:0,
									amount:0
								}]
							}else{
								this.bidsList=!res.bids||!res.bids.length? [{price:0,amount:0}] : res.bids;
								this.asksList=!res.asks||!res.asks.length?[{price:0,amount:0}]:res.asks.reverse();
								// this.bidsList=res.bids;
								// this.asksList=res.asks?res.asks.reverse():res.asks;
							}
						})
					}else{
						// 获取opensea盘口信息
						// 请求
					}
				},15000)
			},
			// 加载更多订单列表
			loadOrders(){
				this.loading = true
				// 下一页
				this.page++;

				var currency
				if(this.web!='opensea'){
					currency=this.yenUnit1+'_'+this.yenUnit2;
				}else{
					currency=this.yenUnit1;
				}
				getOrdersList({
					web:this.web,
					account:this.account,
					currency,
					page:this.page,
				}).then(res=>{
					this.loading = false;
					if(!!res&&res.length>0&&res.length>=10){
						this.orderList.push(...res);
					}else{
						this.load_disabled=true;
					}
				})
			},
			// 交易所
			closeExchange() {
				this.popExchange = false;
				// 子组件判定初始化
				if(this.isSonPop){
					this.isSonPop=false;
					// 还原交易所数据
					this.web=this.web2;
					this.web2 = null;
				}
			},
			handleSelect(data) {
				if(this.isSonPop){
					this.$refs.robotForm.handleSelect(data,this.sonFormNum)
					// 子组件判定初始化
					this.isSonPop=false;
					this.popExchange = false;
					// 还原交易所数据
					this.web=this.web2;
					this.web2 = null;
				}else{
					this.popexchange = data.text;
					this.popweb = data.web;
					this.popweb_id=data.web_id
					this.popweb_type=data.web_type
					
					this.popExchange = false;
				}
			},
			// 子账号管理-进入
			handleManage(){
				// 判定是否有子账号 有则跳转/subManage
				this.$router.push({
					path:"/subManage",
					query:{
						web:this.web,
						yenUnit1:this.yenUnit1,
						yenUnit2:this.yenUnit2,
					}
				})
			},
			// 量化工具-进入
			handleCreate(item) {
				this.isTool = true;
				this.selectTool=item;
				this.toolform = {};
				if(this.selectTool=='transaction'){
					this.$set(this.toolform,'strategy','1')
				}
			},
			// 量化工具-开通
			handleOpen(){
				// 请您联系商务客服续费！
				this.$message({
					message: this.$t('tip.renewTip'),
					type: 'info',
					center: true,
					customClass: 'shotMsg',
				});
				// this.$router.push({
				// 	path:'/renew'
				// })
			},
			// 量化工具-返回
			handleClose() {
				this.isTool = false;
				this.toolform = {};
			},
			// dex量化工具
			handleHelp() {
				this.showHelp = true;
			},
			// dex手动下单
			handleHelp2() {
				this.showHelp2 = true;
			},
			// 量化工具-提交
			submitOrder(approve_type) {
				var inputList=document.querySelectorAll('.order_action_tool .action_form input')
				var empty=false;
				try{
					inputList.forEach(item=>{
						if(!item.value){
							throw('err')
						}
					})
				}catch{
					empty=true;
				}
				// 非空
				if(empty){
					this.$message({
						message: this.$t('tip.dataErr'),
						type: 'error',
						center: true,
						customClass: 'shotMsg',
					});
				}else{
					// 二次确认
					this.$confirm(this.$t('common.confirm')+this.$t('tools.'+this.selectTool+'.name')+'？', this.$t('tip.tips'), {
						confirmButtonText: this.$t('common.confirm'),
						cancelButtonText: this.$t('common.back'),
						center: true,
						showClose: false,
						customClass: 'deleteOk',
					}).then((res) => {
						// console.log(this.toolform);
						var currency
						if(this.web!='opensea'){
							currency=this.yenUnit1+'_'+this.yenUnit2
						}else{
							currency=this.yenUnit1
						}

						if(this.web!='opensea'){

							if(this.selectTool=='check'||this.selectTool=='sale'){
								if(this.toolform.totalNum>500){
									this.$message({
										message: this.$t('tip.total500'),
										type: 'error',
										center: true,
										customClass: 'shotMsg',
									});
								}else{
									this.$message({
										message: this.$t('tip.processing'),
										type: 'success',
										center: true,
										customClass: 'shotMsg',
									});
									// 批量下买单/卖单
									batchTradeCoin({
										web:this.web,
										account:this.account,
										currency,
										price_low:this.toolform.lowPrice,
										price_high:this.toolform.highPrice,
										num_low:this.toolform.lowNum,
										num_high:this.toolform.highNum,
										order_num:this.toolform.totalNum,
										price_div:this.toolform.decimal,
										side:this.selectTool=='check'? 'bids':'asks',
									}).then(res=>{
										this.$message({
											message: this.$t('tip.operationSuc'),
											type: 'success',
											center: true,
											customClass: 'shotMsg',
										});
										this.getData()
										this.getPankouAll()
									})
								}
							}else if(this.selectTool=='revoke'){
								this.$message({
									message: this.$t('tip.processing'),
									type: 'success',
									center: true,
									customClass: 'shotMsg',
								});
								// 批量撤单
								batchTradeCancel({
									web:this.web,
									account:this.account,
									currency,
									price_low:this.toolform.lowPrice,
									price_high:this.toolform.highPrice,
									num_low:this.toolform.lowNum,
									num_high:this.toolform.highNum,
								}).then(res=>{
									this.$message({
										message: this.$t('tip.operationSuc'),
										type: 'success',
										center: true,
										customClass: 'shotMsg',
									});
									this.getData()
									this.getPankouAll()
								})
							}else if(this.selectTool=='calculate'){
								this.$message({
									message: this.$t('tip.processing'),
									type: 'success',
									center: true,
									customClass: 'shotMsg',
								});
								// Dex交易量测算
								calNumberDex({
									web:this.web,
									currency,
									account:this.account,
									target_price:this.toolform.target,
									target_num:0,
								}).then(res=>{
									// console.log(res,'calNumberDex')
									var msg
									if(res['target_num']<0){
										// 需卖出
										var num=(-res['target_num'])+' '+this.yenUnit1.toUpperCase()
										msg=this.$t('tip.needSell',{num:num})
									}else{
										var num=res['target_num']+' '+this.yenUnit1.toUpperCase()
										msg=this.$t('tip.needBuy',{num:num})
									}
									this.$confirm(this.$t('tip.calculationSuc')+msg, this.$t('tip.tips'), {
										confirmButtonText: this.$t('common.confirm'),
										center: true,
										showClose: false,
										customClass: 'deleteOk',
										showCancelButton:false,
									});
								})
							}else if(this.selectTool=='transaction'){
								// Dex批量交易
								// 交易类型、交易策略 选项
								if(!this.toolform.transactionType||!this.toolform.strategy){
									this.$message({
										message: this.$t('tip.dataErr'),
										type: 'error',
										center: true,
										customClass: 'shotMsg',
									});
								}else{
									if(this.toolform.strategy==1){
										// 交易策略-系统默认
										this.$message({
											message: this.$t('tip.processing'),
											type: 'success',
											center: true,
											customClass: 'shotMsg',
										});

										batchTradeDex({
											web:this.web,
											currency,
											account:this.account,
											trade_amount:this.toolform.volume,
											trade_type:this.toolform.transactionType,
											approve_type:approve_type=='again'?'0':'1',
											strategy:this.toolform.strategy
										}).then(res=>{
											if(res.code==2107||(res.code==2109&&res.type=='now')){
												// 未授权
												// 提示授权中。。
												this.$message({
													message: this.$t('tip.processing'),
													type: 'success',
													center: true,
													customClass: 'shotMsg',
												});
												// 授权代币请求
												dexWalletsApprove({
													web:this.web,
													account:this.account,
													approve_token:res.data.replace(',','_'),//代币
													is_master_approve:0,
												}).then(re=>{
													if(re.code==2172&&re.type=='confirm'){
														// 分拨授权
														this.address_list=re.data.address_list
														this.id_list=re.data.id_list
														this.chain=re.data.chain
														this.token=re.data.gas_token
														this.approve_token=re.data.approve_token
														this.transferWeb=this.web
														this.transferExchange=this.exchange
														this.$refs.subTransfer.open()
													}else if(re.code==2172&&re.type=='ignore'){
														// 忽略，重新操作
														this.submitOrder('again')
													}else{
														// 授权成功
														this.$confirm(this.$t('tip.authorizeSuc'), this.$t('tip.tips'), {
															confirmButtonText: this.$t('common.confirm'),//'继续操作',
															// showConfirmButton:false,
															center: true,
															showClose: false,
															customClass: 'deleteOk',
															// cancelButtonText: this.$t('common.back'),
															showCancelButton:false,
														})
													}
												})
											}
											else if(res.code==2109&&res.type=='ignore'){
												// 忽略，重新操作
												this.submitOrder('again')
											}else if(res.code==2109&&res.type=='confirm'){
												// 分拨授权
												this.address_list=res.data.address_list
												this.id_list=res.data.id_list
												this.chain=res.data.chain
												this.token=res.data.gas_token
												this.approve_token=res.data.approve_token
												this.transferWeb=this.web
												this.transferExchange=this.exchange
												this.$refs.subTransfer.open()
											}else{
												// console.log(res,'batchTradeDex')
												this.$message({
													message: this.$t('tip.operationSuc'),
													type: 'success',
													center: true,
													customClass: 'shotMsg',
												});

												this.getData()
												this.getPankouAll()
											}
										})
									}else{
										// 交易策略-条件交易
											this.$message({
												message: this.$t('tip.processing'),
												type: 'success',
												center: true,
												customClass: 'shotMsg',
											});

											batchTradeDex({
												web:this.web,
												currency,
												account:this.account,
												trade_amount:this.toolform.volume,
												trade_type:this.toolform.transactionType,
												approve_type:approve_type=='again'?'0':'1',
												strategy:this.toolform.strategy,
												trade_wallet:this.toolform.walletsNumber,
												range_float:this.toolform.floatingRange!=0?this.toolform.floatingRange/100:this.toolform.floatingRange||0
											}).then(res=>{
												if(res.code==2107||(res.code==2109&&res.type=='now')){
													// 未授权
													// 提示授权中。。
													this.$message({
														message: this.$t('tip.processing'),
														type: 'success',
														center: true,
														customClass: 'shotMsg',
													});
													// 授权代币请求
													dexWalletsApprove({
														web:this.web,
														account:this.account,
														approve_token:res.data.replace(',','_'),//代币
														is_master_approve:0,
													}).then(re=>{
														if(re.code==2172&&re.type=='confirm'){
															// 分拨授权
															this.address_list=re.data.address_list
															this.id_list=re.data.id_list
															this.chain=re.data.chain
															this.token=re.data.gas_token
															this.approve_token=re.data.approve_token
															this.transferWeb=this.web
															this.transferExchange=this.exchange
															this.$refs.subTransfer.open()
														}else if(re.code==2172&&re.type=='ignore'){
															// 忽略，重新操作
															this.submitOrder('again')
														}else{
															// 授权成功
															this.$confirm(this.$t('tip.authorizeSuc'), this.$t('tip.tips'), {
																confirmButtonText: this.$t('common.confirm'),//'继续操作',
																// showConfirmButton:false,
																center: true,
																showClose: false,
																customClass: 'deleteOk',
																// cancelButtonText: this.$t('common.back'),
																showCancelButton:false,
															})
														}
													})
												}
												else if(res.code==2109&&res.type=='ignore'){
													// 忽略，重新操作
													this.submitOrder('again')
												}else if(res.code==2109&&res.type=='confirm'){
													// 分拨授权
													this.address_list=res.data.address_list
													this.id_list=res.data.id_list
													this.chain=res.data.chain
													this.token=res.data.gas_token
													this.approve_token=res.data.approve_token
													this.transferWeb=this.web
													this.transferExchange=this.exchange
													this.$refs.subTransfer.open()
												}else{
													// console.log(res,'batchTradeDex')
													this.$message({
														message: this.$t('tip.operationSuc'),
														type: 'success',
														center: true,
														customClass: 'shotMsg',
													});

													this.getData()
													this.getPankouAll()
												}
											})
									}
								}
							}else if(this.selectTool=='empower'){
								this.$message({
									message: this.$t('tip.processing'),
									type: 'success',
									center: true,
									customClass: 'shotMsg',
								});
								// Dex钱包授权
								dexWalletsApprove({
									web:this.web,
									account:this.account,
									approve_token:this.toolform.token,
									is_master_approve:2,//this.toolform.walletType,
									approve_source:1
								}).then(res=>{
									// console.log(res,'dexWalletsApprove')
									if(res.code==2173){
										// 分拨授权
										this.address_list=res.data.address_list
										this.id_list=res.data.id_list
										this.chain=res.data.chain
										this.token=res.data.gas_token
										this.approve_token=res.data.approve_token
										this.transferWeb=this.web
										this.transferExchange=this.exchange
										this.$refs.subTransfer.open()
									}else{
										// this.$message({
										// 	message: this.$t('tip.operationSuc'),
										// 	type: 'success',
										// 	center: true,
										// 	customClass: 'shotMsg',
										// });
										// 授权成功
										this.$confirm(this.$t('tip.authorizeSuc'), this.$t('tip.tips'), {
											confirmButtonText: this.$t('common.confirm'),//'继续操作',
											// showConfirmButton:false,
											center: true,
											showClose: false,
											customClass: 'deleteOk',
											// cancelButtonText: this.$t('common.back'),
											showCancelButton:false,
										})
									}
								})
							}
						}else{
							// opensea 敬请期待
							this.$message({
								message: this.$t('tip.waiting'),
								center: true,
								customClass: 'shotMsg',
							});
						}
					})
				}
			},
			
			// 手动下单-买入/卖出
			handleOrder(trade_type) {
				// console.log(this.form);
				// 非空验证
				if(!this.form.number){
					this.$message({
						message: this.$t('tip.numEmpty'),
						type: 'error',
						center: true,
						customClass: 'shotMsg',
					});
				}else if(!this.form.price){
					this.$message({
						message: this.$t('tip.priceEmpty'),
						type: 'error',
						center: true,
						customClass: 'shotMsg',
					});
				}else{
					// 二次确认
					this.$confirm(this.$t('tip.'+(trade_type==1?'manuallyBuy':'manuallySell')), this.$t('tip.tips'), {
						confirmButtonText: this.$t('common.confirm'),
						cancelButtonText: this.$t('common.back'),
						center: true,
						showClose: false,
						customClass: 'deleteOk',
					}).then(() => {
						// console.log(this.toolform);
						if(this.web!='opensea'){
							this.$message({
								message: this.$t('tip.processing'),
								type: 'success',
								center: true,
								customClass: 'shotMsg',
							});
							var currency
							if(this.web!='opensea'){
								currency=this.yenUnit1+'_'+this.yenUnit2;
							}else{
								currency=this.yenUnit1;
							}
							tradeCoin({
								web:this.web,
								account:this.account,
								currency,
								num:this.form.number,
								price:this.form.price,
								trade_type,
								approve_type:'1',
							}).then(res=>{
								if(trade_type==1){
									// 买入成功
									this.$message({
										message: this.$t('tip.buySuc'),
										type: 'success',
										center: true,
										customClass: 'shotMsg',
									});
								}else{
									// 卖出成功
									this.$message({
										message: this.$t('tip.sellSuc'),
										type: 'success',
										center: true,
										customClass: 'shotMsg',
									});
								}
								// 获取账户余额、订单列表
								this.getData();
								this.getPankouAll()
								this.form = {};
							})
						}else{
							// opensea 敬请期待
							this.$message({
								message: this.$t('tip.waiting'),
								center: true,
								customClass: 'shotMsg',
							});
						}
					})
				}
			},
			// 撤单
			handleCancel(item,type) {
				this.$confirm(this.$t('tip.cancelOrder'), this.$t('tip.tips'), {
					confirmButtonText: this.$t('common.confirm'),
					cancelButtonText: this.$t('common.back'),
					center: true,
					showClose: false,
					customClass: 'deleteOk',
				}).then(() => {
					var currency
					if(this.web!='opensea'){
						currency=this.yenUnit1+'_'+this.yenUnit2;
					}else{
						currency=this.yenUnit1;
					}
					tradeCancel({
						web:this.web,
						account:this.account,
						currency,
						order_id:item.id,
						type:type?type:item.side,
					}).then(res=>{
						this.$message({
							message: this.$t('tip.cancelSuc'),
							type: 'success',
							center: true,
							offset: 100,
							customClass: 'shotMsg',
						});
						this.getData()
						this.getPankouAll()
					})
				}).catch(() => {
					// 取消
				});
			},
			// 跳转高级盘口
			handleFull() {
				// var url=this.$route.fullPath
				// if(url.indexOf('?')>=0){
				// 	var queryArr=url.split('?')[1].split('&')
				// 	var query={};
				// 	queryArr.forEach(item=>{
				// 		var dataArr=item.split('=')
				// 		query[dataArr[0]]=dataArr[1]
				// 	})
				// 	this.$router.push({
				// 		path:'/stocksFull',
				// 		query
				// 	})
				// }else{
					// this.$router.push({
					// 	path:'/stocksFull'
					// })
				// }
				if(this.web_type==2){
					// opensea 敬请期待
					this.$message({
						message: this.$t('tip.waiting'),
						center: true,
						customClass: 'shotMsg',
					});
				}else{
					this.$router.push({
						path:'/stocksFull'
					})
				}
			},

			// 移动端判定相关方法
			// 打开选择量化工具弹窗
			openToolSelect(){
				this.tool_box_show=true
			},
			// 选择量化工具
			handleCreate2(item,power) {
				if(power){
					this.isTool = true;
					this.selectTool=item;
					this.toolform = {};
					this.tool_box_show=false
					if(this.selectTool=='transaction'){
						this.$set(this.toolform,'strategy','1')
					}
				}else{
					this.handleOpen(item)
					this.tool_box_show=false
				}
			},
			// 进入子钱包管理页面
			handleManage2(power){
				if(power){
					this.handleManage()
					this.tool_box_show=false
				}else{
					this.handleOpen(item)
					this.tool_box_show=false
				}
			},
		},
		watch:{
			device:()=>{
				that.$nextTick(()=>{
					var sell_end=document.getElementById('sell_end');
					sell_end?.scrollIntoView(false)
				});
			},
		},
		beforeDestroy() {
			if(timeR){
				clearInterval(timeR)
				timeR=''
			}
			if(timeR2){
				clearInterval(timeR2)
				timeR2=''
			}
		},
	})
</script>
